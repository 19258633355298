/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { FunctionComponent, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'config/store';
import urlParam from 'constants/urlParam';
// import SearchFeature from 'feature/search/domains';
import { autoLogin, autoLoginReset } from '../feature/auth/actions/AutoLogin';
import Login from '../feature/auth/pages/Login';
import {
  CreditPermissions,
  CreditTabPermissions,
  FleetOnboardingPermissions,
  OrderPermissions,
} from '../constants/Permissions';
import PrivateRoute from './PrivateRoute';

// const Dashboard = React.lazy(() => import('../feature/dashboard/pages/Dashboard'));
// const Permission = React.lazy(() => import('../feature/permission/index'));
// const CreditFeature = React.lazy(() => import('../feature/credit/domains/index'));
// const CustomerCreditProfileDetails = React.lazy(() => import('../feature/credit/domains/customers/CustomerCreditProfileDetails'));
// const OrderProfile = React.lazy(() => import('../feature/credit/domains/orders/OrderProfile'));
// const SupplyOrderProfile = React.lazy(() => import('feature/scheduleOrder/domains/SupplyOrderProfile'));
// const SupplyOrderList = React.lazy(() => import('feature/scheduleOrder/domains/SupplyOrderList'));
// const CreditApplicationProfileDetails = React.lazy(() => import('feature/credit/domains/applications/CreditApplicationProfileDetails'));
// const FleetOnboardingModule = React.lazy(() => import('../feature/fleetOnboarding/domains/index'));
// const VehicleDetails = React.lazy(() => import('../feature/fleetOnboarding/domains/vehicles/VehicleDetails'));
// const PeopleDetails = React.lazy(() => import('../feature/fleetOnboarding/domains/people/PeopleDetails'));
const SearchFeature = React.lazy(
  () => import('../feature/search/domains/index'),
);
const CustomerSearchPage = React.lazy(
  () => import('../feature/search/customerSearch/index'),
);
const EnquirySearchPage = React.lazy(
  () => import('../feature/search/EnquirySearch/index'),
);
const ProjectSearchPage = React.lazy(
  () => import('../feature/search/projectSearch/index'),
);
const DealSearchPage = React.lazy(
  () => import('../feature/search/DealSearch/index'),
);
const DCGSearchPage = React.lazy(
  () => import('../feature/search/dcgSearch/index'),
);
const DCGSubmitedPage = React.lazy(
  () => import('../feature/search/components/DCGSubmitedPage'),
);
const RootNavigation: FunctionComponent = () => {
  const dispatch = useDispatch();
  const isJWTAvailable = localStorage.getItem('userLoginToken');
  const loginState = useTypedSelector((state) => state.loginState);
  const userLogedSuccessfully = loginState.isSuccess;
  const isUserAtLoginPage = window.location.pathname === '/login';

  useEffect(() => {
    if (!userLogedSuccessfully) {
      if (isJWTAvailable) {
        dispatch(autoLogin({ info: { device: { deviceName: 'desktop' } } }));
        return () => { dispatch(autoLoginReset()); };
      }
      if (isUserAtLoginPage) {
        return () => {};
      }
      window.location.href = '/login';
    }
    return () => {};
  }, [isJWTAvailable]);

  let routePath = '/';
  if (urlParam.pincode && urlParam.searchString) {
    routePath = `/:${urlParam.pincode}?/:${urlParam.searchString}?`;
  } else if (urlParam.pincode) {
    routePath = `/:${urlParam.pincode}?`;
  } else if (urlParam.searchString) {
    routePath = `/:${urlParam.searchString}?`;
  }
  return (
    <Router>
      {/* TODO: need to added a suspence page once design available */}
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route path="/login" component={Login} />
          <PrivateRoute exact path="/customer" reqModulePermission={[]}>
            <CustomerSearchPage />
          </PrivateRoute>
          <PrivateRoute exact path="/enquiry" reqModulePermission={[]}>
            <EnquirySearchPage />
          </PrivateRoute>
          <PrivateRoute exact path="/projects" reqModulePermission={[]}>
            <ProjectSearchPage />
          </PrivateRoute>
          <PrivateRoute exact path="/deals" reqModulePermission={[]}>
            <DealSearchPage />
          </PrivateRoute>
          <PrivateRoute exact path="/DCG/submited" reqModulePermission={[]}>
            <DCGSubmitedPage />
          </PrivateRoute>
          <PrivateRoute exact path={`/DCG${routePath}`} reqModulePermission={[]}>
            <DCGSearchPage />
          </PrivateRoute>
          {/* PrivateRoute for product details with optional pincode also adds searchEnter value if there */}
          {/* PrivateRoute for product list with optional pincode */}
          <PrivateRoute path={routePath} reqModulePermission={[]}>
            <SearchFeature />
          </PrivateRoute>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default RootNavigation;
