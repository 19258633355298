import { createAction, ActionType } from 'typesafe-actions';

import { IPageInfo } from '../../../models/PageInfo';
import { IInternalUser } from '../models/InternalUserList';
import { IErrorActionData } from '../../../models/Error';
import { IPage } from '../../../utils/sortFilterFramework';

export enum GetInternalUserListActionTypes {
  GET_INTERNAL_USER_LIST = 'GET_INTERNAL_USER_LIST',
  GET_INTERNAL_USER_LIST_SUCCESS = 'GET_INTERNAL_USER_LIST_SUCCESS',
  GET_INTERNAL_USER_LIST_FAILED = 'GET_INTERNAL_USER_LIST_FAILED',
  GET_INTERNAL_USER_LIST_RESET = 'GET_INTERNAL_USER_LIST_RESET',
}

interface IGetInternalUserListActionData {
  page?: IPage;
}

interface IGetInternalUsersSuccessActionData {
  internalUserList: Array<IInternalUser>;
  pageInfo: IPageInfo;
}

export const getInternalUserList = createAction(GetInternalUserListActionTypes.GET_INTERNAL_USER_LIST)<
IGetInternalUserListActionData
>();
export const getInternalUserListSuccess = createAction(
  GetInternalUserListActionTypes.GET_INTERNAL_USER_LIST_SUCCESS,
)<IGetInternalUsersSuccessActionData>();
export const getInternalUserListFailure = createAction(
  GetInternalUserListActionTypes.GET_INTERNAL_USER_LIST_FAILED,
)<IErrorActionData>();

export const getInternalUserListReset = createAction(GetInternalUserListActionTypes.GET_INTERNAL_USER_LIST_RESET)();

export type GetInternalUserListAction = ActionType<typeof getInternalUserList>;
type GetInternalUserListSuccessAction = ActionType<typeof getInternalUserListSuccess>;
type GetInternalUserListFailureAction = ActionType<typeof getInternalUserListFailure>;
type GetInternalUserListResetAction = ActionType<typeof getInternalUserListReset>;

export type GetInternalUserListActions = GetInternalUserListAction
| GetInternalUserListSuccessAction
| GetInternalUserListFailureAction
| GetInternalUserListResetAction;
