/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getUserDetailsSuccess,
  getUserDetailsFailure,
  GetUserDetailsAction,
  GetUserDetailsActionTypes,
} from '../actions/GetUserDetails';
import getUserDetailsService from '../services/GetUserDetails';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetUserDetailsEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetUserDetailsActionTypes.GET_USER_DETAILS)),
  mergeMap((action: GetUserDetailsAction) => from(
    getUserDetailsService(action.payload.aliasId),
  ).pipe(
    map((response) => getUserDetailsSuccess({
      data: response.data.userDetails.data,
      aliasId: action.payload.aliasId,
    })),
    catchError((error: IErrorActionData) => of(
      getUserDetailsFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
        aliasId: action.payload.aliasId,
      }),
    )),
  )),
);

export default GetUserDetailsEpic;
