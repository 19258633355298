interface IBrand {
  productBrandAliasId: string;
  value: string;
  count: number;
}

interface ICategory {
  productCategoryAliasId: string;
  value: string;
  count: number;
}

interface IFilterMetadata {
  priority: number;
  selection_type: string;
}

interface IPriceFilter {
  end: number;
  start: number;
}
interface ISort {
  key: string;
  order: string;
  value: string;
}

export interface IProductFilterList {
  brand: IBrand[];
  category: ICategory[];
  custom_attributes: any;
  filterAttributesMetadata: {
    Brand: IFilterMetadata;
    Category: IFilterMetadata;
    Price: IFilterMetadata;
    Sort: IFilterMetadata;
  }
  price: IPriceFilter[];
  sort: ISort[];
}

export class ProductFilterList {
  data = {} as IProductFilterList;

  constructor(productFilterList: IProductFilterList) {
    this.data = productFilterList;
  }
}
