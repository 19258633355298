/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IGetDealListPayload } from '../models/DealList';

const GET_DEAL_LIST = gql`
  query getDealList($path: String!) {
    getDealList(path: $path) @rest(method: "GET", path: $path, endpoint: "searchPlaygroundUri") {
      data
      pageInfo
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getDealListServiceV1 = (payload: IGetDealListPayload) => {
  const {
    cursor, pageSize,
  } = payload;

  // eslint-disable-next-line max-len, @typescript-eslint/restrict-template-expressions
  const url = `${URL.getDealListV1}?${payload.queryParams?.toString()}&page_size=${pageSize}&cursor=${cursor}`;
  // eslint-disable-next-line no-console
  console.log('url_', url);

  return client.query({
    query: GET_DEAL_LIST,
    variables: { path: url.toString() },
  });
};

export default getDealListServiceV1;
