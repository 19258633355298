import { createAction, ActionType } from 'typesafe-actions';
import { IErrorActionData } from '../../../models/Error';
import { IPageInfo } from '../../../models/PageInfo';
import { IGetDealListPayload, IDeal } from '../models/DealList';

export enum GetDealListActionTypes {
  GET_DEAL_LIST_V1 = 'GET_DEAL_LIST_V1',
  GET_DEAL_LIST_SUCCESS_V1 = 'GET_DEAL_LIST_SUCCESS_V1',
  GET_DEAL_LIST_FAILED_V1 = 'GET_DEAL_LIST_FAILED_V1',
  GET_DEAL_LIST_RESET_V1 = 'GET_DEAL_LIST_RESET_V1',
}

interface IGetDealListActionData {
  payload: IGetDealListPayload;
}

interface IGetDealListSuccessActionData {
  dealList: Array<IDeal>;
  pageInfo: IPageInfo;
}

// create actions
export const getDealListV1 = createAction(GetDealListActionTypes.GET_DEAL_LIST_V1)<IGetDealListActionData>();
export const getDealListSuccess = createAction(
  GetDealListActionTypes.GET_DEAL_LIST_SUCCESS_V1,
)<IGetDealListSuccessActionData>();
export const getDealListFailure = createAction(
  GetDealListActionTypes.GET_DEAL_LIST_FAILED_V1,
)<IErrorActionData>();
export const getDealListReset = createAction(GetDealListActionTypes.GET_DEAL_LIST_RESET_V1)();

export type GetDealListAction = ActionType<typeof getDealListV1>;
type GetDealListSuccessAction = ActionType<typeof getDealListSuccess>;
type GetDealListFailureAction = ActionType<typeof getDealListFailure>;
type GetDealListResetAction = ActionType<typeof getDealListReset>;

export type GetDealListActionsV1 = GetDealListAction
| GetDealListSuccessAction
| GetDealListFailureAction
| GetDealListResetAction;
