import _ from 'lodash';

import { IIdLabelPair } from 'models/Common';

export enum FilterOperands {
  EQ = '', // it is empty string because default = will be available, but still needed in FilterOperands for readability
  NE = '_ne',
  LT = '_lt',
  LTE = '_le',
  GT = '_gt',
  GTE = '_ge',
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export interface IPage {
  pageSize?: number;
  cursor?: number | string;
  pageNumber?: number | string;
}

export interface ISort {
  property?: string;
  direction?: SortDirection;
}

export interface IFilter {
  property: string;
  operand: FilterOperands;
  value: any;
}

/**
 *
 * @param filters
 */

export const convertFilterToUrlParams = (filters: IFilter[]) => filters.reduce(
  (prevResult, item) => (item.value ? prevResult.concat(
    _.snakeCase(item.property), item.operand, '=', Array.isArray(item.value) ? `[${item.value.toString()}]` : item.value, '&',
  ) : prevResult), '',
);

/**
 *
 * @param filters with comma separated string
 */

export const convertFilterToUrlParamsAsString = (filters: IFilter[]) => filters.reduce(
  (prevResult, item) => (item.value ? prevResult.concat(
    _.snakeCase(item.property), item.operand, '=', Array.isArray(item.value) ? `${item.value.toString()}` : item.value, '&',
  ) : prevResult), '',
);

/**
 *
 * @param sortObj
 */

export const convertSortToUrlParams = (sortObj: ISort = {}) => {
  if (!sortObj.property) return '';
  return 'order_by='.concat(_.snakeCase(sortObj.property || ''), '&order_type=', sortObj.direction || SortDirection.ASC, '&');
};

/**
 *
 * @param pageInfo
 */

export const convertPageInfoToUrlParams = (pageInfo: IPage = {}) => {
  let pageInfoQuery = '';
  if (pageInfo.pageSize) pageInfoQuery = 'page_size='.concat(pageInfo.pageSize.toString(), '&');
  if (pageInfo.cursor) pageInfoQuery = pageInfoQuery.concat(`cursor=${pageInfo.cursor}`);
  if (pageInfo.pageNumber) pageInfoQuery = pageInfoQuery.concat(`page_number=${pageInfo.pageNumber}`);
  return pageInfoQuery;
};

export const constructUrlWithParams = (url: string, filter: IFilter[] = [], sort?: ISort, pageInfo?: IPage) => url.concat('?',
  convertFilterToUrlParams(filter),
  convertSortToUrlParams(sort),
  convertPageInfoToUrlParams(pageInfo));

export const constructUrlWithParamsAsString = (url: string, filter: IFilter[] = [], sort?: ISort, pageInfo?: IPage) => url.concat('?',
  convertFilterToUrlParamsAsString(filter),
  convertSortToUrlParams(sort),
  convertPageInfoToUrlParams(pageInfo));

export const sortSearchedNames = (inputValue: string) => (a: IIdLabelPair, b: IIdLabelPair): number => {
  const [aFirstName, aLastName] = a.label?.split(' ') ?? [];
  const [bFirstName, bLastName] = b.label?.split(' ') ?? [];
  if (aFirstName?.toLowerCase()?.startsWith(inputValue.toLowerCase())
    && bFirstName?.toLowerCase()?.startsWith(inputValue.toLowerCase())) {
    return -1;
  } if (aFirstName?.toLowerCase()?.startsWith(inputValue.toLowerCase())) {
    return -1;
  } if (bFirstName?.toLowerCase()?.startsWith(inputValue.toLowerCase())) {
    return 1;
  }
  if (aLastName?.toLowerCase()?.startsWith(inputValue.toLowerCase())
    && bLastName?.toLowerCase()?.startsWith(inputValue.toLowerCase())) {
    return -2;
  } if (aLastName?.toLowerCase().startsWith(inputValue.toLowerCase())) {
    return -2;
  } if (bLastName?.toLowerCase()?.startsWith(inputValue.toLowerCase())) {
    return 2;
  }
  return 1;
};
