import React, { FunctionComponent } from 'react';
import {
  Box, InputAdornment, TextField, Typography,
} from '@mui/material';
import clsx from 'clsx';

import { CountryCodes, CurrencyCodes, DistanceCodes } from 'constants/Common';
import useStyles from './styles';

type ClassType = {
  input?: string;
  error?: string;
  container?: string;
  helperText?: string;
};

interface IIMInputProps {
  inputLabel: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<any>) => void;
  type: 'text' | 'number' | 'password' | 'email';
  id?: string;
  name?: string;
  required?: boolean;
  defaultValue?: string;
  variant?: 'standard' | 'filled' | 'outlined';
  onBlur?: (e: React.FocusEvent<any>) => void;
  onKeyPress?: (e: React.KeyboardEvent<any>) => void;
  error?: string;
  currencyCountryCode?: CountryCodes;
  distanceCode?: DistanceCodes;
  helperText?: string,
  classes?: ClassType;
  disabled?: boolean;
}

const IMInput: FunctionComponent<IIMInputProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.container, props.classes?.container)}>
      <TextField
        id={props.id}
        name={props.name}
        required={props.required}
        label={props.inputLabel}
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={(event) => {
          if (event.target.value.trim() !== '' || event.target.value === '') {
            props.onChange(event);
          }
        }}
        onKeyPress={props.onKeyPress}
        onFocus={props.onBlur}
        type={props.type}
        variant={props.variant || 'outlined'}
        className={clsx(classes.input, props.classes?.input)}
        InputProps={{
          startAdornment: props.currencyCountryCode
          && <InputAdornment position="start">{CurrencyCodes[props.currencyCountryCode]}</InputAdornment>,
          endAdornment: props.distanceCode
          && <InputAdornment position="end">{props.distanceCode}</InputAdornment>,
        }}
        error={!!props.error}
        disabled={!!props.disabled}
      />
      {!!props.helperText && <Typography className={clsx(classes.text, props.classes?.helperText)}>{props.helperText}</Typography>}
      {!!props.error && <Typography className={clsx(classes.text, classes.error, props.classes?.error)}>{props.error}</Typography>}
    </Box>
  );
};

export default IMInput;
