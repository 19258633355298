/* eslint-disable import/prefer-default-export */

export enum VehicleStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export enum CreditApplicationStates {
  New = 'new',
  Submitted = 'submitted',
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum OrderStates {
  New = 'new',
  Submitted = 'submitted',
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum CreditState {
  Active = 'active',
  Inactive = 'inactive',
}

export enum CustomerType {
  Enterprise = 'Enterprise',
  Retail = 'Retailer',
}

export enum CreditCustomerType {
  Enterprise = 'enterprise',
  Retailer = 'retailer',
  Dealer = 'dealer',
  IMDealer = 'im_dealer',
}

export enum CustomerStates {
  New = 'new',
  Active = 'active',
  CreditApproved = 'credit_approved',
  CreditPending = 'credit_pending',
}

export const CustomerStatesMap = new Map([
  ['new', 'New'],
  ['active', 'Active'],
  ['credit_pending', 'Credit Pending'],
  ['credit_approved', 'Credit Approved'],
]);

export enum ServerDataTypes {
  CustomersList = 'CustomersList',
}

export enum CustomerDataFields {
  stateField = 'state',
}

export enum KycStatus {
  Pending = 'pending',
  Verified = 'verified',
  Rejected = 'rejected',
  Failed = 'failed',
  NA = 'not_applicable',
}

export enum KycProfileType {
  User = 'user',
  Customer = 'customer',
  CustomerSite = 'customer_site',
  CustomerOffice = 'customer_office',
  CustomerRepresentative = 'customer_representative',
  CreditApplication = 'credit_application',
  Product = 'product',
  Supplier = 'supplier',
  SupplierOffice = 'supplier_office',
}

export enum KycTypes {
  Aadhar = 'aadhar',
  PAN = 'pan',
  GST = 'gst',
  Gst06 = 'gst_06',
  GstReturns = 'gst_returns',
  CancelledCheque = 'cancelled_cheque',
  PostDatedCheque = 'post_dated_cheque',
  LetterOfCredit = 'letter_of_credit',
  BankGuarantee = 'bank_guarantee',
  BankStatement = 'bank_statement',
  CreditReport = 'credit_report',
  ReraNo = 'rera_no',
  Address = 'address',
  AddressProof = 'address_proof',
  SecurityCheck = 'security_check',
  CIN = 'cin',
  LLPIN = 'llpin',
  registrationCertificate = 'registration_certificate',
  KYCForm = 'kyc_form',
}

export enum SecurityDocType {
  PostDatedCheque = 'post_dated_cheque',
  LetterOfCredit = 'letter_of_credit',
  BankGuarantee = 'bank_guarantee',
  SecurityCheck = 'security_check',
  None = 'none',
}

export const SecurityDocTypeMap = new Map([
  ['post_dated_cheque', 'Post Dated Cheque'],
  ['letter_of_credit', 'Letter of Credit'],
  ['bank_guarantee', 'Bank Guarantee'],
  ['security_check', 'Security Check'],
  ['none', 'None'],
]);

export const CustomerTypeMap = new Map([
  ['retail', 'Retail'],
  ['retailer', 'Retail'],
  ['enterprise', 'Enterprise'],
  ['dealer', 'Dealer'],
  ['im_dealer', 'IM Dealer'],
]);

export enum CustomerTypeTitle {
  retail = 'Retailer',
  retailer = 'Retailer',
  enterprise = 'Enterprise',
  dealer = 'Dealer',
  im_dealer = 'IM Dealer',
}

export enum CompanyType {
  Limited = 'limited',
  LLP = 'llp',
  Partnership = 'partnership',
  PrivateLimited = 'private_limited',
  Proprietorship = 'proprietorship',
  Individual = 'individual',
  HUF = 'huf',
  Society = 'society',
  Trust = 'trust',
  NA = '',
}

export const BusinessTypeMap = new Map([
  ['retailer', 'Retailer'],
  ['road_contractor', 'Road Contractor'],
  ['builder', 'Builder'],
  ['general_contractor', 'General Contractor'],
  ['manufacturer', 'Manufacturer'],
  ['other', 'Other'],
]);

export const CompanyTypeMap = new Map([
  ['limited', 'Limited'],
  ['llp', 'LLP'],
  ['partnership', 'Partnership'],
  ['private_limited', 'Private Limited'],
  ['proprietorship', 'Proprietorship'],
  ['individual', 'Individual'],
  ['huf', 'HUF'],
  ['society', 'Society'],
  ['trust', 'Trust'],
  ['other', 'Other'],
]);

export const ProductTypeMap = new Map([
  ['rmc', 'Concrete'],
  ['aggregate', 'Aggregates'],
]);

export const RejectReasons = [
  { id: 'pdc_required', label: 'PDC required' },
  { id: 'bg_required', label: 'BG required' },
  { id: 'legal_case', label: 'Legal case' },
  { id: 'nclt_case', label: 'NCLT case' },
  { id: 'gst_status_inactive', label: 'GST Status - Inactive' },
  { id: 'gst_status_cancelled', label: 'GST Status - Cancelled' },
  { id: 'income_tax_case', label: 'Income tax case' },
  { id: 'gst_not_filing', label: 'GST not filing' },
  { id: 'cheque_bounce_case', label: 'Cheque bounce case' },
  { id: 'company_revenue_not_profitable ', label: 'Company Revenue - Not Profitable' },
  { id: 'payment_issue', label: 'Payment issue' },
  { id: 'multiple_applications_were_raised', label: 'Multiple applications were raised' },
  { id: 'gst_copy_not_attached', label: 'GST copy not attached' },
  { id: 'pan_copy_not_attached', label: 'PAN copy not attached' },
  { id: 'aadhaar_copy_not_attached', label: 'Aadhaar copy not attached' },
  { id: 'others', label: 'Others' },
];

export const RejectReasonsRetail = [
  { id: 'kyc_form_required', label: 'KYC form required' },
  { id: 'security_type_required', label: 'Security type required' },
  { id: 'balance_confirmation_pending', label: 'Balance confirmation pending' },
  { id: 'overdue_reflecting', label: 'Overdue reflecting' },
  { id: 'legal_case', label: 'Legal case' },
  { id: 'gst_status_inactive', label: 'GST Status - Inactive' },
  { id: 'gst_status_cancelled', label: 'GST Status - Cancelled' },
  { id: 'income_tax_case', label: 'Income tax case' },
  { id: 'gst_not_filing', label: 'GST not filing' },
  { id: 'cheque_bounce_case', label: 'Cheque bounce case' },
  { id: 'company_revenue_not_profitable ', label: 'Company Revenue - Not Profitable' },
  { id: 'payment_issue', label: 'Payment issue' },
  { id: 'others', label: 'Others' },
];

export const CreditApplicationStateSelectionList = [
  { id: 'submitted', label: 'Pending' },
  { id: 'approved', label: 'Approved' },
  { id: 'rejected', label: 'Rejected' },
];

export const CreditOrderStateSelectionList = [
  { id: 'pending', label: 'Pending' },
  { id: 'approved', label: 'Approved' },
  { id: 'rejected', label: 'Rejected' },
];

export const CreditOrderStateSelectionListEnterprise = [
  { id: 'awaiting_credit_check', label: 'Pending' },
  { id: 'credit_approved', label: 'Approved' },
  { id: 'credit_rejected', label: 'Rejected' },
];

export const companyTypeSelectionList = [
  { id: 'limited', label: 'Limited' },
  { id: 'llp', label: 'LLP' },
  { id: 'partnership', label: 'Partnership' },
  { id: 'private_limited', label: 'Private Limited' },
  { id: 'proprietorship', label: 'Proprietorship' },
  { id: 'individual', label: 'Individual' },
  { id: 'huf', label: 'HUF' },
  { id: 'society', label: 'Society' },
  { id: 'trust', label: 'Trust' },
];

export const CustomerStateSelectionList = [
  { id: 'new', label: 'New' },
  { id: 'prospect', label: 'Prospect' },
  { id: 'active', label: 'Active' },
];

export enum SortOrder {
  AZ = 'A-Z',
  ZA = 'Z-A',
  Highest = 'HIGHEST',
  Lowest = 'LOWEST',
  Oldest = 'OLDEST',
  Latest = 'LATEST',
}

export enum AvailableSortOptions {
  dateModified = 'modifiedAtTs',
  updatedAt = 'updatedAt',
  siteName = 'customerSiteName',
  customer = 'customerName',
  creditAmount = 'proposedCredit',
  totalCredit = 'totalCreditLimit',
  availableCredit = 'availableCreditLimit',
  customerAliasId = 'customerAliasId',
  orderAliasId = 'orderAliasId',
  productGroupAliasId = 'productGroupAliasId',
  customerSiteAliasId = 'siteAliasId',
  minimumOrderValue = 'minimumOrderValue',
  maximumOrderValue = 'maximumOrderValue',
  status = 'state',
  internalUserAliasID = 'ownerAliasId',
  gstAliasId = 'gstAliasId',
  pendingApplications = 'pendingApplicationCount',
  pendingOrders = 'pendingOrderCount',
}

export const CreditDaysOptions = [
  { id: 'payment_on_delivery', label: 'Payment on Delivery' },
  { id: '7_days', label: '7' },
  { id: '15_days', label: '15' },
  { id: '30_days', label: '30' },
  { id: '45_days', label: '45' },
  { id: '60_days', label: '60' },
  { id: '75_days', label: '75' },
  { id: '90_days', label: '90' },
];
export const CreditDaysOptionsRetail = [
  { id: 'payment_on_delivery', label: 'Payment on Delivery' },
  { id: '10_days', label: '10' },
  { id: '15_days', label: '15' },
  { id: '21_days', label: '21' },
  { id: '30_days', label: '30' },
  { id: '45_days', label: '45' },
];
export const CreditDaysMap = new Map([
  ['payment_on_delivery', 'Payment on Delivery'],
  ['7_days', '7'],
  ['15_days', '15'],
  ['30_days', '30'],
  ['45_days', '45'],
  ['60_days', '60'],
  ['75_days', '75'],
  ['90_days', '90'],
]);

export enum SupplyOrderStates {
  New = 'new',
  InProgress = 'in_progress',
  PartiallyScheduled = 'partially_scheduled',
  Scheduled = 'scheduled',
  InTransit = 'in_transit',
  PartiallyDelivered = 'partially_delivered',
  Delivered = 'delivered',
  Cancelled = 'cancelled',
  Expired = 'expired',
  Closed = 'closed',
  Pending = 'pending',
  AwaitingCreditCheck = 'awaiting_credit_check',
  CreditApproved = 'credit_approved',
  CreditRejected = 'credit_rejected',
}

export const SupplyOrderStatesMap = new Map([
  ['new', 'New'],
  ['in_progress', 'In Progress'],
  ['partially_scheduled', 'Partially Scheduled'],
  ['scheduled', 'Scheduled'],
  ['in_transit', 'In Transit'],
  ['partially_delivered', 'Partially Delivered'],
  ['delivered', 'Delivered'],
  ['cancelled', 'Cancelled'],
  ['expired', 'Expired'],
  ['closed', 'Closed'],
  ['awaiting_credit_check', 'Pending'],
  ['credit_approved', 'Approved'],
  ['credit_rejected', 'Rejected'],
]);

export enum ScheduleOrderStates {
  New = 'new',
  InProgress = 'in_progress',
  Scheduled = 'scheduled',
  PartiallyDelivered = 'partially_delivered',
  Delivered = 'delivered',
  Cancelled = 'cancelled',
  Expired = 'expired',
}

export const ScheduleOrderStatesMap = new Map([
  ['new', 'New'],
  ['in_progress', 'In Progress'],
  ['scheduled', 'Scheduled'],
  ['partially_delivered', 'Partially Delivered'],
  ['delivered', 'Delivered'],
  ['cancelled', 'Cancelled'],
  ['expired', 'Expired'],
]);

export const EditSupplyOrderReasonMap = new Map([
  ['job_rescheduled', 'Job Rescheduled'],
  ['vendor_unavailability', 'Vendor Unavailability'],
  ['tm_unavailability', 'TM Unavailability'],
  ['pump_unavailability', 'Pump Unavailability'],
  ['manpower_unavailability', 'Manpower Unavailability'],
  ['quality_issue', 'Quality Issue'],
  ['batching_plant_issue', 'Batching Plant Issue'],
  ['pump_failure', 'Pump Failure'],
  ['tm_breakdown', 'TM Breakdown'],
  ['neighbor_issue', 'Neighbor Issue'],
]);

export enum CreditOrderStates {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
  AutoApproved = 'auto_approved',
}

export const CreditOrderStatesMap = new Map([
  ['pending', 'Pending'],
  ['approved', 'Approved'],
  ['rejected', 'Rejected'],
  ['auto_approved', 'Auto Approved'],
]);

export const PaymentTypeMap = new Map([
  ['100%_advance', '100% Advance'],
  ['50%_advance_50%_credit', '50% Advance 50% Credit'],
  ['100%_credit', '100% Credit'],
]);

export enum AliasPrefixMap {
  CustomerRepresentative = 'CREP',
}

export enum DepartmentType {
  Management = 'management',
  Logistics = 'logistics',
}

export enum CoordinatorTypeMap {
  Site = 'site',
  Pickup = 'pickup',
}

export enum GSTInfo {
  cgstPercentage = 9.0,
  sgstPercentage = 9.0,
  igstPercentage = 0.0,
}

export enum AvailableServices {
  Transportation = 'Transit Mixer',
  LinePump = 'Line Pump',
  BoomPump = 'Boom Pump',
}

export enum AvailableServiceTypes {
  Logistic = 'logistic',
  Pumping = 'pumping',
}

export const PlantServiceSelectionOptions = [
  { id: 'logistic', label: 'Transport Provided' },
  { id: 'pumping', label: 'Pump Provided' },
];

export const aggregatePlantServiceSelectionOptions = [
  { id: 'logistic', label: 'Transport Available' },
];

export const SupplyOrderStatusOptions = [
  { id: 'credit_approved', label: 'Credit Approved' },
  { id: 'in_progress', label: 'Partially Scheduled' },
  { id: 'scheduled', label: 'Scheduled' },
  { id: 'partially_delivered', label: 'Partially Delivered' },
  { id: 'delivered', label: 'Delivered' },
  { id: 'cancelled', label: 'Cancelled' },
  { id: 'expired', label: 'Expired' },
  { id: 'closed', label: 'Closed' },
];

export enum LocalStorageFilter {
  SupplyOrderListRMC = 'supplyOrderListRMC',
  SupplierListAggregate = 'supplierListAggregate',
  SupplyOrderListAggregate = 'supplyOrderListAggregate',
  CreditApplicationListEnterprise = 'creditApplicationListEnterprise',
  CustomerCreditListEnterprise = 'customerCreditListEnterprise',
  OrderListEnterprise = 'orderListEnterprise',
  CreditApplicationListRetail = 'creditApplicationListRetail',
  CustomerCreditListRetail = 'customerCreditListRetail',
  OrderListRetail = 'orderListRetail',
  PlantList = 'plantList',
  FleetOnboardingVehicleList = 'fleetOnboardingVehicleList',
  ProductFilterList = 'productFilterList',
}

export enum TabName {
  SupplyOrderListRMC = 'supplyOrderListRMC',
  SupplierListAggregate = 'supplierListAggregate',
  SupplyOrderListAggregate = 'supplyOrderListAggregate',
  CreditApplicationListEnterprise = 'creditApplicationListEnterprise',
  CustomerCreditListEnterprise = 'customerCreditListEnterprise',
  OrderListEnterprise = 'orderListEnterprise',
  CreditApplicationListRetail = 'creditApplicationListRetail',
  CustomerCreditListRetail = 'customerCreditListRetail',
  OrderListRetail = 'orderListRetail',
  PlantList = 'plantList',
  Vehicles = 'vehicles',
  People = 'people',
  Products = 'products',
}

export const CreditTabNameMap = new Map([
  ['creditApplicationListEnterprise', 'enterprise'],
  ['customerCreditListEnterprise', 'enterprise'],
  ['orderListEnterprise', 'enterprise'],
  ['creditApplicationListRetail', 'retail'],
  ['customerCreditListRetail', 'retail'],
  ['OrderListRetail', 'retail'],
]);

export enum TabContext {
  Enterprise = 'enterprise',
  Retail = 'retail',
}

export enum TabLabel {
  Enterprise = 'ENTERPRISE',
  Retail = 'RETAIL',
}

export enum RelationshipType {
  Enterprise = 'enterprise',
  Retail = 'retail',
}

export enum FeatureList {
  Applications = 'applications',
  Customers = 'customers',
  Products = 'products',
  Orders = 'orders',
  FleetOnboarding = 'fleetOnboarding',
}

export const PumpTypeMap = new Map([
  ['line_pump', 'Line Pump'],
  ['boom_pump', 'Boom Pump'],
  ['manual_pump', 'Manual Pump'],
]);

export const SupplyOrderReasonMap = new Map([
  ['job_rescheduled', 'Job Rescheduled'],
  ['vendor_unavailability', 'Vendor Unavailability'],
  ['tm_unavailability', 'TM Unavailability'],
  ['pump_unavailability', 'Pump Unavailability'],
  ['manpower_unavailability', 'Manpower Unavailability'],
  ['quality_issue', 'Quality Issue'],
  ['batching_plant_issue', 'Batching Plant Issue'],
  ['pump_failure', 'Pump Failure'],
  ['tm_breakdown', 'TM Breakdown'],
  ['neighbor_issue', 'Neighbor Issue'],
  ['truck_unavailability', 'Truck Unavailability'],
  ['truck_breakdown', 'Truck Breakdown'],
  ['crusher_plant_issue', 'Crusher Plant Issue'],
]);

export enum StringFormats {
  PlusMore = 'plusMore',
  AndMore = 'andMore',
  AndSymbol = 'andSymbol',
}

export enum StringFormatValues {
  PlusMore = ' + {count} more',
  AndMore = ' & {count} more',
  AndSymbol = ' & ',
}

export enum DateTimeLabelFormat {
  DateFormat = 'dateFormat',
  DateTimeFormat = 'dateTimeFormat',
}

export enum SupplierTypeAggregate {
  Manufacturer = 'manufacturer',
  Trader = 'trader',
}

export enum OptionalStrings {
  DoubleDash = '--',
}

export enum UnitOfMeasurements {
  MetricTon = 'MT',
  CubicMeter = 'Cubic Meter',
}

export enum DrawerType {
  Reject = 'reject',
  Approve = 'approve',
}

export enum DefaultFilterForCreditCustomer{
  CreditGroupName = 'credit_group_name',
}

export enum DefaultFilterForOrderList{
  OrderBy = 'order_by',
  GstNumber = 'gst_number',
  customerSiteAliasId = 'customerSiteAliasId',
}

export enum OrderListFilterValue {
  ModifiedAt = 'updated_at',
  CustomerName = 'customer_name',
  OrderValue = 'total_selling_price',
}

export const CreditGroupNameMap = new Map([
  ['retail', 'retail'],
  ['enterprise', 'enterprise'],
]);

export const OrderGroupNameMap = new Map([
  ['retail', 'retail'],
]);

export enum DefaultCursorValue {
  CustomerList = 'CUST0',
  BranchList = 'COFF0',
}

export enum PartnerType{
  Driver = 'driver',
}

export enum CreditApplicationActionType {
  Approve = 'approve',
  Reject = 'reject',
}

export enum AdditionalConstants {
  NotApplicable = 'N/A',
}

export enum ProductName {
  RMC = 'RMC',
}

export enum ProductCategory {
  RMC = 'rmc',
  Aggregate = 'aggregate',
}

export const ProductGroupNameMap = new Map([
  ['Concrete', 'RMC'],
  ['Aggregates', 'Aggregates'],
]);

export enum ServiceType {
  Kyc = 'kyc',
  Order = 'Order',
}

export enum GenericConstants {
  NA = 'NA',
}

export const IMStates = {
  ...SupplyOrderStates,
  ...ScheduleOrderStates,
  ...CreditApplicationStates,
  ...CustomerTypeTitle,
  ...CustomerStates,
  ...RelationshipType,
  ...CreditCustomerType,
  Assign: 'assign',
  OrderProcessing: 'order_processing',
  PartiallyShipped: 'partially_shipped',
  Shipped: 'shipped',
};

export type IMStatesType = SupplyOrderStates | ScheduleOrderStates | CreditApplicationStates
| CustomerTypeTitle | CustomerStates | RelationshipType | CreditCustomerType;

export const vehicleUnitsDisplayMap = new Map([
  ['m3', 'm3'],
  ['mt', 'MT'],
  ['mtr', 'Mtr'],
  ['cft', 'CFT'],
  ['q.', 'Q.'],
]);
