/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Reducer } from 'redux';

import { ProjectList, mergeTwoLists } from '../models/ProjectList';
import { GetProjectListActionTypes, GetProjectListActionsV1 } from '../actions/GetProjectList';
import { PageInfo } from '../../../models/PageInfo';
import { IErrorActionData } from '../../../models/Error';
import { defaultPageSize } from '../../../constants/Common';

export interface IProjectListStateV1 {
  projectList: ProjectList;
  pageInfo: PageInfo;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialProjectListState = {
  projectList: new ProjectList([]),
  esQuery: null,
  pageInfo: new PageInfo({ pageSize: defaultPageSize, cursor: 0, hasNext: true }),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IProjectListStateV1;

const GetProjectListReducerV1: Reducer<IProjectListStateV1, GetProjectListActionsV1> = (
  state = initialProjectListState,
  action: GetProjectListActionsV1,
): IProjectListStateV1 => {
  switch (action.type) {
    case GetProjectListActionTypes.GET_PROJECT_LIST_V1:
      console.log('initialed', action.type);
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetProjectListActionTypes.GET_PROJECT_LIST_SUCCESS_V1: {
      console.log('success', action.type);

      const newProjectList = mergeTwoLists(
        state.projectList.projectList,
        action.payload.projectList,
      );
      console.log('action_payload_project_list', action.payload);
      return {
        ...state,
        projectList: new ProjectList(newProjectList),
        pageInfo: new PageInfo({
          cursor: action.payload.pageInfo.cursor,
          pageSize: action.payload.pageInfo.pageSize,
          hasNext: action.payload.pageInfo.hasNext,
          pageNumber: action.payload.pageInfo.pageNumber,
          totalPages: action.payload.pageInfo.totalPages,
        }),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetProjectListActionTypes.GET_PROJECT_LIST_FAILED_V1:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetProjectListActionTypes.GET_PROJECT_LIST_RESET_V1:
      return initialProjectListState;

    default:
      return state;
  }
};

export default GetProjectListReducerV1;
