export interface IEnquiry {
  enquiringEntityName: string;
  enquiryAliasId: string;
  source: number;
  enquiryCreatedDateTs: string;
  requirement: string;
  state: string;
  subStatus: string;
  showTimer: string;
  followUpDetails: {
    priority: string,
    followUpDate: number
  };
  assignedToDetails:{
    userAliasId: number,
    name: string,
    assignedDate: number,
    roles: Array<string>
  };
  address:{
    city:string,
    state:string
  };
  statusUpdatedAtTs:number
}

export class EnquiryList {
  enquiryList: Array<IEnquiry> = [];

  constructor(enquiryList: Array<IEnquiry>) {
    this.enquiryList = enquiryList;
  }
}

export const mergeTwoEnquiryLists = (
  existingList: Array<IEnquiry>,
  payloadList: Array<IEnquiry>,
): Array<IEnquiry> => {
  if (!existingList.length) return payloadList;
  if (!payloadList.length) return existingList;
  return existingList.concat(payloadList);
};

export interface IGetEnquiryListPayload {
  cursor?: number | string;
  pageSize: number;
  searchString?: string
  queryParams?: URLSearchParams;
  creationStartDateGe?: string;
  creationEndDateLe?: string;
  followUpStartDateGe?: string;
  followUpEndDateLe?: string;
  city?: string;
  state?: string;
  enquiryStatus?: string;
  source?: string;
  userAliasId?: string;
}
