import { IErrorActionData } from '../../../models/Error';

export interface IUserDetails {
  userAliasId?: string;
  firstName?: string;
  lastName?: string;
  isActive?: boolean;
  kycStatus?: string;
  designation?: string;
  department?: string;
  phoneNumber?: string;
  email?: string;
  modifiedBy?:string;
  modifiedAt?:string;
}

export interface IUserStateDetails {
  userDetails: IUserDetails;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

export class UsersDetails {
  usersDetails: Record<string, IUserStateDetails>;
  constructor(userMetaDetails: Record<string, IUserStateDetails>) {
    this.usersDetails = userMetaDetails;
  }
}

export const addUserDetailsToExisting = (existingUsers: Record<string, IUserStateDetails>,
  aliasId: string, newUserData: IUserStateDetails) => (
  { ...existingUsers, [aliasId]: newUserData }
);
