/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getInternalUserListSuccess,
  getInternalUserListFailure,
  GetInternalUserListAction,
  GetInternalUserListActionTypes,
} from '../actions/GetInternalUserList';
import getInternalUserListService from '../services/GetInternalUserList';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetInternalUserListEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetInternalUserListActionTypes.GET_INTERNAL_USER_LIST)),
  mergeMap((action: GetInternalUserListAction) => from(
    getInternalUserListService(action.payload.page),
  ).pipe(
    map((response) => getInternalUserListSuccess({
      internalUserList: response.data.internalUserList.data,
      pageInfo: response.data.internalUserList.pageInfo,
    })),
    catchError((error: IErrorActionData) => of(
      getInternalUserListFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetInternalUserListEpic;
