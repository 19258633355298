import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';
import { LoginMode, LoginType } from '../../../constants/Common';
import { IUserProfileData } from '../models/UserProfile';

export enum LoginActionTypes {
  LOGIN_ACTION = 'LOGIN_ACTION',
  LOGIN_ACTION_SUCCESS = 'LOGIN_ACTION_SUCCESS',
  LOGIN_ACTION_FAILURE = 'LOGIN_ACTION_FAILURE',
  LOGIN_ACTION_RESET = 'LOGIN_ACTION_RESET',
}

export interface ILoginActionData {
  loginID: string;
  loginType: LoginType;
  loginMode: LoginMode;
  password: string;
  info?: {
    device: {
      deviceName: string;
    }
  }
}

export interface ILoginSuccessActionData {
  loginID: string;
  otpTimeout: number;
}

export const login = createAction(LoginActionTypes.LOGIN_ACTION)<ILoginActionData>();
export const loginSuccess = createAction(LoginActionTypes.LOGIN_ACTION_SUCCESS)<ILoginSuccessActionData>();
export const loginFailure = createAction(LoginActionTypes.LOGIN_ACTION_FAILURE)<IErrorActionData>();
export const loginReset = createAction(LoginActionTypes.LOGIN_ACTION_RESET)();

export type LoginAction = ActionType<typeof login>;
type LoginSuccessAction = ActionType<typeof loginSuccess>;
type LoginFailureAction = ActionType<typeof loginFailure>;
type LoginResetAction = ActionType<typeof loginReset>;

export type LoginActions =
  | LoginAction
  | LoginSuccessAction
  | LoginFailureAction
  | LoginResetAction;
