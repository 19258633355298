export interface IInternalUser {
  imRepName: string;
  imRepAliasId: string;
  imRepPhoneNumber: string;
}

export class InternalUserList {
  internalUserList: Array<IInternalUser> = [];
  constructor(internalUserList: Array<IInternalUser>) {
    this.internalUserList = internalUserList;
  }
}

export const mergeTwoLists = (
  existingList: Array<IInternalUser>,
  payloadList: Array<IInternalUser>,
) => {
  if (!existingList.length) return payloadList;
  if (!payloadList.length) return existingList;
  return existingList.concat(payloadList);
};
