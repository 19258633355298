import { combineReducers } from 'redux';

import LoginReducer from 'feature/auth/reducers/Login';
import AutoLoginReducer from 'feature/auth/reducers/AutoLogin';
import VerifyOtpReducer from 'feature/auth/reducers/VerifyOTP';
import ResendOtpReducer from 'feature/auth/reducers/ResendOTP';
import LogoutReducer from 'feature/auth/reducers/Logout';
import GetUserProfileReducer from 'feature/auth/reducers/GetUserProfile';
import GetUserDetailsReducer from 'feature/user/reducers/GetUserDetails';
import GetUserDetailsListReducer from 'feature/user/reducers/GetUserDetailsList';
import GetInternalUserListReducer from 'feature/user/reducers/GetInternalUserList';
import GetFilterListReducerV1 from 'feature/search/reducers/GetProductFilterList';
import GetAutoSuggestReducerV1 from 'feature/search/reducers/GetAutoSuggest';
import GetDealListReducerV1 from 'feature/search/reducers/GetDealList';
import GetProductListReducerV4 from '../../feature/search/reducers/GetProductList';
import GetCustomerListReducerV1 from '../../feature/search/reducers/GetCustomerList';
import GetEnquiryListReducerV1 from '../../feature/search/reducers/GetEnquiryList';
import GetProjectListReducerV1 from '../../feature/search/reducers/GetProjectList';
import GetProductDetailsReducerV4 from '../../feature/search/reducers/GetProductDetails';

const RootReducer = combineReducers({
  loginState: LoginReducer,
  autoLogin: AutoLoginReducer,
  verifyOtp: VerifyOtpReducer,
  resendOtp: ResendOtpReducer,
  logoutState: LogoutReducer,
  userProfile: GetUserProfileReducer,
  userDetails: GetUserDetailsReducer,
  userDetailsList: GetUserDetailsListReducer,
  productListV4: GetProductListReducerV4,
  customerListV1: GetCustomerListReducerV1,
  enquiryListV1: GetEnquiryListReducerV1,
  projectListV1: GetProjectListReducerV1,
  internalUserList: GetInternalUserListReducer,
  productFilterList: GetFilterListReducerV1,
  autoSuggest: GetAutoSuggestReducerV1,
  productDetailsV4: GetProductDetailsReducerV4,
  dealListV1: GetDealListReducerV1,
});

export default RootReducer;
