import { createAction, ActionType } from 'typesafe-actions';
import { IErrorActionData } from '../../../models/Error';
import { IPageInfo } from '../../../models/PageInfo';
import { IGetCustomerListPayload, ICustomer } from '../models/CustomerList';

// eslint-disable-next-line import/prefer-default-export
export enum GetCustomerListActionTypes {
  GET_CUSTOMER_LIST_V1 = 'GET_CUSTOMER_LIST_V1',
  GET_CUSTOMER_LIST_SUCCESS_V1 = 'GET_CUSTOMER_LIST_SUCCESS_V1',
  GET_CUSTOMER_LIST_FAILED_V1 = 'GET_CUSTOMER_LIST_FAILED_V1',
  GET_CUSTOMER_LIST_RESET_V1 = 'GET_CUSTOMER_LIST_RESET_V1',
}

interface IGetCustomerListActionData {
  payload: IGetCustomerListPayload;
}

interface IGetCustomerSuccessActionData {
  customerList: Array<ICustomer>;
  pageInfo: IPageInfo;
}

// create actions
export const getCustomerListV1 = createAction(GetCustomerListActionTypes.GET_CUSTOMER_LIST_V1)<IGetCustomerListActionData>();
export const getCustomerListSuccess = createAction(
  GetCustomerListActionTypes.GET_CUSTOMER_LIST_SUCCESS_V1,
)<IGetCustomerSuccessActionData>();
export const getCustomerListFailure = createAction(
  GetCustomerListActionTypes.GET_CUSTOMER_LIST_FAILED_V1,
)<IErrorActionData>();
export const getCustomerListReset = createAction(GetCustomerListActionTypes.GET_CUSTOMER_LIST_RESET_V1)();

export type GetCustomerListAction = ActionType<typeof getCustomerListV1>;
type GetCustomerListSuccessAction = ActionType<typeof getCustomerListSuccess>;
type GetCustomerListFailureAction = ActionType<typeof getCustomerListFailure>;
type GetCustomerListResetAction = ActionType<typeof getCustomerListReset>;

export type GetCustomerListActionsV1 = GetCustomerListAction
| GetCustomerListSuccessAction
| GetCustomerListFailureAction
| GetCustomerListResetAction;
