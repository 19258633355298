/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Reducer } from 'redux';

import { EnquiryList, mergeTwoEnquiryLists } from '../models/EnquiryList'; // Adjust the import
import { GetEnquiryListActionTypes, GetEnquiryListActionsV1 } from '../actions/GetEnquiryList'; // Adjust the import
import { PageInfo } from '../../../models/PageInfo';
import { IErrorActionData } from '../../../models/Error';
import { defaultPageSize } from '../../../constants/Common';

export interface IEnquiryListStateV1 {
  enquiryList: EnquiryList; // Adjust the type
  pageInfo: PageInfo;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialEnquiryListState = {
  enquiryList: new EnquiryList([]), // Adjust the type
  pageInfo: new PageInfo({ pageSize: defaultPageSize, cursor: 0, hasNext: true }),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IEnquiryListStateV1;

const GetEnquiryListReducerV1: Reducer<IEnquiryListStateV1, GetEnquiryListActionsV1> = (
  state = initialEnquiryListState,
  action: GetEnquiryListActionsV1,
): IEnquiryListStateV1 => {
  switch (action.type) {
    case GetEnquiryListActionTypes.GET_ENQUIRY_LIST_V1:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetEnquiryListActionTypes.GET_ENQUIRY_LIST_SUCCESS_V1: {
      const isAppend = ((state.pageInfo.cursor ?? 0) < (action.payload.pageInfo?.cursor ?? 0)
          && (state.pageInfo.cursor as number) % 10 === 0)
        || ((action.payload.pageInfo.cursor as number) > 10 && !action.payload.pageInfo.hasNext)
        || ((action.payload.pageInfo.cursor as number) === 10
          && !action.payload.pageInfo.hasNext
          && action.payload.enquiryList === null);

      const newEnquiryList = isAppend ? mergeTwoEnquiryLists( // Adjust the function name and type
        state.enquiryList.enquiryList,
        action.payload.enquiryList,
      ) : state.enquiryList.enquiryList;
      return {
        ...state,
        enquiryList: new EnquiryList(newEnquiryList), // Adjust the type and property name
        pageInfo: new PageInfo({
          pageSize: action.payload.pageInfo.pageSize,
          cursor: action.payload.pageInfo.cursor,
          hasNext: action.payload.pageInfo.hasNext,
        }),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetEnquiryListActionTypes.GET_ENQUIRY_LIST_FAILED_V1:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetEnquiryListActionTypes.GET_ENQUIRY_LIST_RESET_V1:
      return initialEnquiryListState;

    default:
      return state;
  }
};

export default GetEnquiryListReducerV1;
