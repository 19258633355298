/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { IIdLabelPair } from 'models/Common';
import { CountryCodes, CurrencyCodes, TimeConversion } from '../constants/Common';
import { StringFormats, StringFormatValues, TabContext } from '../constants/Strings';
import { PermissionApiStructure, Permission } from '../feature/auth/models/UserProfile';

export const getFeaturePermissionCheck = (permissionList: Record<string, Record<string, Permission>>, requiredPermission: string[]) => {
  if (!requiredPermission?.length) return true;
  return (Object.keys(permissionList?.module ?? {}).some((item) => requiredPermission?.includes(item)));
};

export const formatCurrency = (amount?: number | string, country?: CountryCodes) => {
  if (!amount) return country ? `${CurrencyCodes[country]} 0` : '0';
  const amountString: string = typeof amount === 'number' ? amount.toString() : amount;
  const [numeric, decimal] = amountString.split('.');
  return (country ? `${CurrencyCodes[country]} ` : '')
    .concat(numeric.replace(/(\d)(?=(\d\d)+\d$)/g, '$1,'))
    .concat(decimal ? `.${decimal}` : '');
};

/**
 *
 * @param source
 * @param idAlias
 * @param labelAlias
 */
export const generateIdLabelPairs = (source: Array<any>, idAlias: string, labelAlias: string) => source.map(
  (item) => ({ id: item[idAlias], label: item[labelAlias] }),
);

/**
 * Function to get Id Label pair from dropdown list
 * @param dropdownList
 * @param id
 */
export const getIdLabelPair = (dropdownList: IIdLabelPair[], id?: string | number): IIdLabelPair => {
  if (id) {
    const selectedOption = dropdownList.find((item) => item.id === id);
    return selectedOption ?? {} as IIdLabelPair;
  }
  return {} as IIdLabelPair;
};

/**
 *
 * @param epochTimeInSec
 * @param format
 */
export const formatEpochTime = (epochTimeInSec?: number, format = 'DD MMM YYYY', isUtcTime?: boolean) => {
  if (!epochTimeInSec) return '-';
  if (isUtcTime) {
    dayjs.extend(utc);
    return dayjs.utc(epochTimeInSec * 1000).local().format(format);
  }
  return dayjs(epochTimeInSec * 1000).format(format);
};

/**
 *
 * @param permissions
 */
export const constructPermission = (permissions?: PermissionApiStructure[]) => {
  const formattedPermission: Record<string, any> = {};
  permissions?.forEach((permission) => {
    if (Object.keys(formattedPermission).includes(permission.level)) {
      formattedPermission[permission.level][permission.name] = {
        parentPermissionId: permission.parentPermissionId,
        hasPermission: true,
      };
    } else {
      formattedPermission[permission.level] = {
        [permission.name]: {
          parentPermissionId: permission.parentPermissionId,
          hasPermission: true,
        },
      };
    }
  });
  return formattedPermission;
};

export const getReducedTitleFromTitleList = (value: string[] | undefined, showTitleCount = 1, format = StringFormats.PlusMore): string => {
  if (!value || value.length === 0) return '';
  let finalString = value[0];

  for (let i = 1; i < showTitleCount; i += 1) {
    if (i < value.length) finalString = finalString.concat(', ', value[i]);
  }

  if (format === StringFormats.PlusMore && (value.length > showTitleCount)) {
    return finalString?.concat(StringFormatValues.PlusMore.replace('{count}', (value.length - showTitleCount).toString()));
  }
  if (format === StringFormats.AndMore && (value.length > showTitleCount)) {
    return finalString?.concat(StringFormatValues.AndMore.replace('{count}', (value.length - showTitleCount).toString()));
  }
  if (format === StringFormats.AndSymbol && (showTitleCount > 1)) {
    return finalString?.substring(0, finalString.lastIndexOf(',')).concat(StringFormatValues.AndSymbol,
      finalString.substring(finalString.lastIndexOf(',') + 2, finalString.length));
  }
  return finalString;
};

export const formatString = (value: string[] | undefined, numberOfStrings = 1, format = StringFormats.PlusMore): string => {
  if (!value || value.length === 0) return String('');
  let stringCount = numberOfStrings;
  if (numberOfStrings <= 0) stringCount = 1;
  let index = 0; let finalString = '';
  while (index < stringCount) {
    finalString = finalString.concat(' ', value[index] ?? '');
    index += 1;
    if (stringCount - index > 0 && index < value.length) finalString = finalString.concat(',');
  }
  if (format === StringFormats.PlusMore) {
    if (value.length === 1 || numberOfStrings >= value.length) return finalString;
    return finalString.concat(StringFormatValues.PlusMore.replace('{count}',
      ((value.length - stringCount <= 0) ? 0 : value.length - stringCount).toString()));
  }
  if (format === StringFormats.AndSymbol) {
    if (value.length <= 1 || numberOfStrings <= 1) return finalString;
    return finalString.substring(0, finalString.lastIndexOf(',')).concat(StringFormatValues.AndSymbol,
      finalString.substring(finalString.lastIndexOf(',') + 2, finalString.length));
  }
  if (format === StringFormats.AndMore) {
    if (value.length === 1 || numberOfStrings >= value.length) return finalString;
    return finalString.concat(StringFormatValues.AndMore.replace('{count}',
      ((value.length - stringCount <= 0) ? 0 : value.length - stringCount).toString()));
  }
  return String('');
};

export const getPermissionCheck = (urlTabContext: string, tabContext: TabContext,
  creditTabPermissions: string, userPermissionsList: string[]) => {
  if (urlTabContext === tabContext && !userPermissionsList.includes(creditTabPermissions)) return true;
  return false;
};

// Use it to get the count label for action label box.
// Function returns a string with 0 appended at prefix if count is less than 10
export const getPreviousCountLabel = (count: number): string => {
  if (count < 10) return `0${count}`;
  if (count >= 10) return count.toString();
  return '00';
};

export const getLocalStorageValue = (key: string) => localStorage.getItem(key);

export const setLocalStorageValue = (key: string, value: any) => {
  if (typeof value === 'string') return localStorage.setItem(key, value);
  return localStorage.setItem(key, JSON.stringify(value));
};

export const clearLocalStorageFilterValue = (key: string) => localStorage.setItem(key, JSON.stringify([]));

export const epochTimeStartOfDay = (time: number) => (dayjs(Number(time) * 1000).startOf('day').unix() + TimeConversion.ISTOffsetInSeconds);

export const epochTimeEndOfDay = (time: number) => (dayjs(Number(time) * 1000).startOf('day').unix()
+ TimeConversion.ISTOffsetInSeconds + (TimeConversion.DayInSeconds - 1));
