/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Reducer } from 'redux';

import { UsersDetails, addUserDetailsToExisting } from '../models/UserDetails';
import { GetUserDetailsActions, GetUserDetailsActionTypes } from '../actions/GetUserDetails';
import { IErrorActionData } from '../../../models/Error';

export interface IUserDetailsState {
  usersDetails: UsersDetails;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialUserDetailsState: IUserDetailsState = {
  usersDetails: new UsersDetails({}),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
};

const GetUserDetailsReducer: Reducer<IUserDetailsState, GetUserDetailsActions> = (
  state = initialUserDetailsState,
  action: GetUserDetailsActions,
): IUserDetailsState => {
  switch (action.type) {
    case GetUserDetailsActionTypes.GET_USER_DETAILS: {
      const updatedUserDetails = addUserDetailsToExisting(
        state.usersDetails.usersDetails,
        action.payload.aliasId,
        { isProcessing: true, isSuccess: false, userDetails: {} },
      );
      return { ...state, usersDetails: new UsersDetails(updatedUserDetails) };
    }

    case GetUserDetailsActionTypes.GET_USER_DETAILS_SUCCESS:
    {
      const updatedUserDetails = addUserDetailsToExisting(
        state.usersDetails.usersDetails,
        action.payload.aliasId,
        {
          isProcessing: false,
          isSuccess: true,
          userDetails: action.payload.data,
        },
      );

      return { ...state, usersDetails: new UsersDetails(updatedUserDetails) };
    }

    case GetUserDetailsActionTypes.GET_USER_DETAILS_FAILED:
    {
      const updatedUserDetails = addUserDetailsToExisting(
        state.usersDetails.usersDetails,
        action.payload.aliasId,
        {
          isProcessing: false,
          isSuccess: false,
          userDetails: {},
          error: {
            errorCode: action.payload.errorCode,
            errorMessage: action.payload.errorMessage,
            callBack: action.payload.callBack,
          },
        },
      );
      return { ...state, usersDetails: new UsersDetails(updatedUserDetails) };
    }

    case GetUserDetailsActionTypes.GET_USER_DETAILS_RESET:
      return initialUserDetailsState;

    default:
      return state;
  }
};
export default GetUserDetailsReducer;
