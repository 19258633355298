import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IResendOtpActionData } from '../actions/ResendOTP';

const LOGIN = gql`
  query resendOtp($path: String!, $data: any) {
    resendOtp(input: $data, path: $path) 
      @rest(method: "POST", path: $path, endpoint: "pheonix") {
        data
    }
  }
`;

const resendOtpService = (payload: IResendOtpActionData) => client.query(
  { query: LOGIN, variables: { data: payload, path: URL.resendOTP } },
);

export default resendOtpService;
