import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { loginReset } from '../../../actions/Login';
import { verifyOtp, verifyOtpReset } from '../../../actions/VerifyOTP';
import { resendOtp, resendOtpReset } from '../../../actions/ResendOTP';
import IMResponse from '../../../../../components/IMResponse';
import Success from '../../../../../assets/images/Success';
import { useTypedSelector } from '../../../../../config/store';
import { LoginMode, LoginType } from '../../../../../constants/Common';
import useStyles from '../styles';

interface IOtpArea {
  phoneNumber: number | string;
}

const OtpArea: FunctionComponent<IOtpArea> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation('auth');
  const dispatch = useDispatch();
  const history = useHistory();

  const loginState = useTypedSelector((state) => state.loginState);
  const verifyOtpState = useTypedSelector((state) => state.verifyOtp);
  const resendOtpState = useTypedSelector((state) => state.resendOtp);

  const [timer, setTimer] = useState<number>(loginState.loginData.otpTimeout);
  const [otp, setOtp] = useState('');

  const resetAll = () => {
    setTimer(0);
    dispatch(loginReset());
    dispatch(verifyOtpReset());
    dispatch(resendOtpReset());
  };

  useEffect(() => resetAll, []);

  useEffect(() => {
    if (timer > 1000) {
      setTimeout(() => setTimer((prevTime) => prevTime - 1000), 1000);
    } else {
      setTimer(0);
    }
  }, [timer]);

  useEffect(() => {
    if (otp.length === 6) {
      dispatch(verifyOtp({
        loginID: `${props.phoneNumber}`,
        loginType: LoginType.phoneno,
        mode: LoginMode.login,
        otp,
      }));
    }
  }, [otp]);

  useEffect(() => {
    if (verifyOtpState.isSuccess) {
      history.replace('/');
    }
  }, [verifyOtpState]);

  useEffect(() => {
    if (resendOtpState.isSuccess) {
      setTimer(loginState.loginData.otpTimeout);
    }
  }, [resendOtpState]);

  const resendOtpHandler = () => {
    if (timer === 0) {
      dispatch(resendOtp({
        loginID: `${props.phoneNumber}`,
        loginType: LoginType.phoneno,
        mode: LoginMode.login,
      }));
    }
  };

  return (
    <Box className={classes.LoginContainer}>
      <IMResponse
        image={Success}
        title={t('searchPlayground')}
        subTitle={t('otpSendMessage').replace('{phno}', props.phoneNumber.toString())}
        classes={{
          container: classes.imageContainer,
          image: classes.image,
        }}
        hidePrimaryBtn
        hideSecondaryBtn
      />
      <OtpInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        containerStyle={classes.otpContainer}
        inputStyle={classes.otpInputBox}
        focusStyle={classes.otpInputBoxFocus}
        isInputNum
        shouldAutoFocus
        isDisabled={verifyOtpState.isProcessing || resendOtpState.isProcessing}
      />
      { !!verifyOtpState.error
        && (
        <Typography className={classes.errorText}>
          {verifyOtpState.error.errorCode === 500 ? t('errorMessage.somethingWentWrong') : t('errorMessage.invalidOtp') }
        </Typography>
        )}
      <Box className={classes.otpBtnContainer}>
        <Button
          variant="text"
          disableElevation
          disableRipple
          startIcon={<ArrowBackIcon />}
          className={classes.backBtn}
          onClick={resetAll}
        >
          {t('back')}
        </Button>
        <Box className={classes.resendBtnContainer}>
          <Button
            variant="outlined"
            disableElevation
            disableRipple
            onClick={resendOtpHandler}
            className={clsx(classes.resendOtp, timer > 0 && classes.resendOtpDisabled)}
          >
            {t('resendOtp')}
          </Button>
          { timer !== 0
            && (
            <Typography className={classes.resendOtpTimer}>
              {t('resendOtpDuration').replace('{sec}', (timer / 1000).toString())}
            </Typography>
            )}
        </Box>
      </Box>
    </Box>
  );
};

export default OtpArea;
