/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import RootAction from 'config/store/RootAction';
import RootState from 'config/store/RootState';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import { IErrorActionData } from 'models/Error';
import {
  GetProductFilterListAction, GetProductFilterListActionTypes, getProductFilterFailure, getProductFilterSuccess,
} from '../actions/GetProductFilterList';
import getProductFilterListServiceV1 from '../services/GetProductFilterList';
import { ProductFilterList } from '../models/ProductFilterList';

const GetFilterListEpicV1 = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetProductFilterListActionTypes.GET_PRODUCT_FILTER_LIST_V1)),
  mergeMap((action: GetProductFilterListAction) => from(
    getProductFilterListServiceV1(
      action.payload.payload,
    ),
  ).pipe(
    map((response) => getProductFilterSuccess({
      filterList: response.data.getProductFilterList.data,
    })),
    catchError((error: IErrorActionData) => of(
      getProductFilterFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetFilterListEpicV1;
