import { ActionType, createAction } from 'typesafe-actions';

import { IErrorActionData } from 'models/Error';
import { IGetProductListPayload } from '../models/ProductList';
import { IProductFilterList } from '../models/ProductFilterList';

export enum GetProductFilterListActionTypes {
  GET_PRODUCT_FILTER_LIST_V1 = 'GET_PRODUCT_FILTER_LIST_V1',
  GET_PRODUCT_FILTER_SUCCESS_V1 = 'GET_PRODUCT_FILTER_SUCCESS_V1',
  GET_PRODUCT_FILTER_FAILURE_V1 = 'GET_PRODUCT_FILTER_FAILURE_V1',
  GET_PRODUCT_FILTER_RESET_V1 = 'GET_PRODUCT_FILTER_RESET_V1',
}

interface IGetProductFilterListActionData {
  payload: IGetProductListPayload;
}

interface IGetProductFilterListSucessActionData {
  filterList: IProductFilterList;
}

export const getProductFilterList = createAction(
  GetProductFilterListActionTypes.GET_PRODUCT_FILTER_LIST_V1,
)<IGetProductFilterListActionData>();
export const getProductFilterSuccess = createAction(
  GetProductFilterListActionTypes.GET_PRODUCT_FILTER_SUCCESS_V1,
)<IGetProductFilterListSucessActionData>();
export const getProductFilterFailure = createAction(
  GetProductFilterListActionTypes.GET_PRODUCT_FILTER_FAILURE_V1,
)<IErrorActionData>();
export const getProductFilterReset = createAction(GetProductFilterListActionTypes.GET_PRODUCT_FILTER_RESET_V1)();

export type GetProductFilterListAction = ActionType<typeof getProductFilterList>;
type GetProductFilterListSuccessAction = ActionType<typeof getProductFilterSuccess>;
type GetProductFilterListFailureAction = ActionType<typeof getProductFilterFailure>;
type GetProductFilterResetAction = ActionType<typeof getProductFilterReset>;

export type GetProductFilterListV1 = GetProductFilterListAction
| GetProductFilterListSuccessAction
| GetProductFilterListFailureAction
| GetProductFilterResetAction;
