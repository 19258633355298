import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IGetProjectListPayload } from '../models/ProjectList';

const GET_PROJECT_LIST = gql`
  query getProjectList($path: String!, $payload: any) {
    getProjectList (path: $path, payload: $payload)
      @rest(method: "GET", path: $path, bodyKey: "payload", endpoint: "searchPlaygroundUri") {
        data
        pageInfo
      }
  }
`;

const getProjectListServiceV1 = (payload: IGetProjectListPayload) => {
  const {
    cursor, pageSize,
  } = payload;
  // eslint-disable-next-line max-len, @typescript-eslint/restrict-template-expressions
  const url = `${URL.getProjectListV1}?&page_size=${pageSize}&cursor=${cursor}&search_string=${payload.searchString}&city=${payload.city}&state=${payload.state}`;
  return client.query({
    query: GET_PROJECT_LIST,
    variables: { path: url.toString() },
  });
};

export default getProjectListServiceV1;
