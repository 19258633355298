import { createAction, ActionType } from 'typesafe-actions';
import { IErrorActionData } from '../../../models/Error';
import { IPageInfo } from '../../../models/PageInfo';
import { IGetProjectListPayload, IProject } from '../models/ProjectList';

// eslint-disable-next-line import/prefer-default-export
export enum GetProjectListActionTypes {
  GET_PROJECT_LIST_V1 = 'GET_PROJECT_LIST_V1',
  GET_PROJECT_LIST_SUCCESS_V1 = 'GET_PROJECT_LIST_SUCCESS_V1',
  GET_PROJECT_LIST_FAILED_V1 = 'GET_PROJECT_LIST_FAILED_V1',
  GET_PROJECT_LIST_RESET_V1 = 'GET_PROJECT_LIST_RESET_V1',
}

interface IGetProjectListActionData {
  payload: IGetProjectListPayload;
}

interface IGetProjectSuccessActionData {
  projectList: Array<IProject>;
  pageInfo: IPageInfo;
}

// create actions
export const getProjectListV1 = createAction(GetProjectListActionTypes.GET_PROJECT_LIST_V1)<IGetProjectListActionData>();
export const getProjectListSuccess = createAction(
  GetProjectListActionTypes.GET_PROJECT_LIST_SUCCESS_V1,
)<IGetProjectSuccessActionData>();
export const getProjectListFailure = createAction(
  GetProjectListActionTypes.GET_PROJECT_LIST_FAILED_V1,
)<IErrorActionData>();
export const getProjectListReset = createAction(GetProjectListActionTypes.GET_PROJECT_LIST_RESET_V1)();

export type GetProjectListAction = ActionType<typeof getProjectListV1>;
type GetProjectListSuccessAction = ActionType<typeof getProjectListSuccess>;
type GetProjectListFailureAction = ActionType<typeof getProjectListFailure>;
type GetProjectListResetAction = ActionType<typeof getProjectListReset>;

export type GetProjectListActionsV1 = GetProjectListAction
| GetProjectListSuccessAction
| GetProjectListFailureAction
| GetProjectListResetAction;
