/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Reducer } from 'redux';

import { CustomerList, mergeTwoLists } from '../models/CustomerList';
import { GetCustomerListActionTypes, GetCustomerListActionsV1 } from '../actions/GetCustomerList';
import { PageInfo } from '../../../models/PageInfo';
import { IErrorActionData } from '../../../models/Error';
import { defaultPageSize } from '../../../constants/Common';

export interface ICustomerListStateV1 {
  customerList: CustomerList;
  pageInfo: PageInfo;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialCustomerListState = {
  customerList: new CustomerList([]),
  esQuery: null,
  pageInfo: new PageInfo({ pageSize: defaultPageSize, cursor: 0, hasNext: true }),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as ICustomerListStateV1;

const GetCustomerListReducerV1: Reducer<ICustomerListStateV1, GetCustomerListActionsV1> = (
  state = initialCustomerListState,
  action: GetCustomerListActionsV1,
): ICustomerListStateV1 => {
  switch (action.type) {
    case GetCustomerListActionTypes.GET_CUSTOMER_LIST_V1:
      console.log('initialed', action.type);
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetCustomerListActionTypes.GET_CUSTOMER_LIST_SUCCESS_V1: {
      console.log('success', action.type);

      const newCustomerList = mergeTwoLists(
        state.customerList.customerList,
        action.payload.customerList,
      );
      console.log('action_payload_deal_list', action.payload);
      return {
        ...state,
        customerList: new CustomerList(newCustomerList),
        pageInfo: new PageInfo({
          cursor: action.payload.pageInfo.cursor,
          pageSize: action.payload.pageInfo.pageSize,
          hasNext: action.payload.pageInfo.hasNext,
          pageNumber: action.payload.pageInfo.pageNumber,
          totalPages: action.payload.pageInfo.totalPages,
        }),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetCustomerListActionTypes.GET_CUSTOMER_LIST_FAILED_V1:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetCustomerListActionTypes.GET_CUSTOMER_LIST_RESET_V1:
      return initialCustomerListState;

    default:
      return state;
  }
};

export default GetCustomerListReducerV1;
