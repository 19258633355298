/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getDealListSuccess,
  getDealListFailure,
  GetDealListAction,
  GetDealListActionTypes,
} from '../actions/GetDealList';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import getDealListServiceV1 from '../services/GetDealList'; // Adjust the import

const GetDealListEpicV1 = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetDealListActionTypes.GET_DEAL_LIST_V1)),
  mergeMap((action: GetDealListAction) => from(
    getDealListServiceV1(
      action.payload.payload,
    ),
  ).pipe(
    map((response) =>
      // eslint-disable-next-line no-console
      // console.log('&&&&&', response.data.getDealList.data.pageInfo);
      // eslint-disable-next-line implicit-arrow-linebreak
      getDealListSuccess({
        dealList: response.data.getDealList.data.data || [],
        pageInfo: {
          pageSize: response.data.getDealList.data.pageInfo.pageSize || 0,
          cursor: response.data.getDealList.data.pageInfo.cursor || 0,
          hasNext: response.data.getDealList.data.pageInfo.hasNext || false,
          pageNumber: response.data.getDealList.data.pageInfo.pageNumber || 1,
          totalPages: response.data.getDealList.data.pageInfo.totalPages || 1,
        },
      })),
    catchError((error: IErrorActionData) => of(
      getDealListFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetDealListEpicV1;
