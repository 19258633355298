export interface IGetDealListPayload {
  cursor?: number | string;
  pageSize: number;
  queryParams?: URLSearchParams;
}

export interface IDeal {
  billingEntityDetails: {
    name: string;
    peopleAliasId: string;
    phoneNumber: string;
    profession: string;
  };
  dealDetails: {
    dealAliasId: string;
    dealName: string;
    dealCreatedTs: number;
    dealStatus: {
      reason: string;
      state: string;
    };
    dealTeamDetails: {
      currentAssigneeDetails: {
        lastInteractionTs: number;
        name: string;
        userAliasId: string;
      };
      ownerDetails: {
        lastInteractionTs: number;
        name: string;
        userAliasId: string;
      };
    };
    followUp: {
      dateTs: number;
      priority: string;
    };
    lastInteraction: {
      date: number;
      type: string;
    };
    potentialAmount: number;
    potentialQuantity: {
      quantity: number;
      skuMeasurementUnitAliasId: string;
    };
    productCategoryDetails: {
      productCategoryAliasId: string;
      productCategoryName: string;
    };
  };
  projectDetails: {
    projectCity: string;
    projectState: string;
    projectType: string;
  };
  channelName: string;
}

export class DealList {
  dealList: Array<IDeal> = [];

  constructor(dealList: Array<IDeal>) {
    this.dealList = dealList;
  }
}

export const mergeTwoDealLists = (
  existingList: Array<IDeal>,
  payloadList: Array<IDeal>,
): Array<IDeal> => {
  if (!existingList.length) return payloadList;
  if (!payloadList.length) return existingList;
  return existingList.concat(payloadList);
};
