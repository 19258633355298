import React, { FunctionComponent, useEffect } from 'react';
import { useFormik } from 'formik';
import { Container, CssBaseline } from '@mui/material';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import CredInputArea from './CredInputArea';
import OtpArea from './OtpArea';
import { login } from '../../actions/Login';
import { LoginMode, LoginType } from '../../../../constants/Common';
import { useTypedSelector } from '../../../../config/store';
import useStyles from './styles';

const loginSchema = yup.object({
  phoneNumber: yup.string().required('phoneNumberReq').matches(/^(?:\d{10})$/, 'inValidPhoneNo'),
});

const Login: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loginState = useTypedSelector((state) => state.loginState);

  const formikData = useFormik({
    initialValues: {
      phoneNumber: '',
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
    },
  });

  useEffect(() => {
    if (loginState.error) {
      if (loginState.error.errorCode === 401) {
        formikData.setFieldError('phoneNumber', 'nonregisteredMob');
      } else {
        formikData.setFieldError('phoneNumber', 'somethingWentWrong');
      }
    }
  }, [loginState.error]);

  const mobOnSubmit = () => {
    if (formikData.isValid && formikData.values.phoneNumber) {
      dispatch(
        login({
          loginID: formikData.values.phoneNumber.toString(),
          loginType: LoginType.phoneno,
          loginMode: LoginMode.otp,
          password: '',
          info: {
            device: {
              deviceName: 'desktop',
            },
          },
        }),
      );
    } else {
      formikData.validateForm();
    }
  };

  return (
    <>
      <CssBaseline />
      <Container className={classes.root}>
        { loginState.isSuccess
          ? <OtpArea phoneNumber={formikData.values.phoneNumber} />
          : (
            <CredInputArea
              phoneNumber={formikData.values.phoneNumber}
              onChange={formikData.handleChange}
              onBlur={formikData.handleBlur}
              onSubmit={mobOnSubmit}
              disableSubmit={formikData.touched && !!formikData.errors.phoneNumber}
              error={formikData.errors?.phoneNumber}
            />
          )}
      </Container>
    </>
  );
};

export default Login;
