/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { gql } from '@apollo/client';

import client from 'config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IGetProductListPayload } from '../models/ProductList';

const GET_PRODUCT_FILTER_LIST = gql`
  query getProductFilterList($path: String!, $payload: any) {
    getProductFilterList (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "searchPlaygroundUri") {
        data
      }
}
`;

const getProductFilterListServiceV1 = (payload: IGetProductListPayload) => client.query({
  query: GET_PRODUCT_FILTER_LIST,
  variables: { path: URL.getProductFilterList, payload },
});

export default getProductFilterListServiceV1;
