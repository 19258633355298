/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import {
  filter, map, mergeMap,
} from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getProductListSuccess,
  getProductListFailure,
  GetProductListAction,
  GetProductListActionTypes,
  GetProductListActionsV4,
} from '../actions/GetProductList';
// import getProductListService from '../services/GetProductList';
import { IErrorActionData } from '../../../models/Error';
import getProductListServiceV4 from '../services/GetProductList';

const GetProductListEpicV4 = (action$: ActionsObservable<GetProductListActionsV4>) => action$.pipe(
  filter(isOfType(GetProductListActionTypes.GET_PRODUCT_LIST_V4)),
  mergeMap((action: GetProductListAction) => from(
    getProductListServiceV4(
      action.payload.payload, action.payload.payload.searchType || 'Default',
    ),
  ).pipe(
    map((response) => (getProductListSuccess({
      productList: response.data.getProductList.data.data || [],
      esQuery: response.data.getProductList.data.esQuery,
      pageInfo: response.data.getProductList.pageInfo,
    }))),
    catchError((error: IErrorActionData) => of(
      getProductListFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetProductListEpicV4;
