import { Reducer } from 'redux';

import { IErrorActionData } from 'models/Error';
import { GetProductFilterListActionTypes, GetProductFilterListV1 } from '../actions/GetProductFilterList';
import { ProductFilterList } from '../models/ProductFilterList';

export interface IProductFilterListStateV1 {
  filterList: ProductFilterList;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialFilterListState = {
  filterList: new ProductFilterList({
    brand: [],
    category: [],
    custom_attributes: null,
    filterAttributesMetadata: {
      Brand: { priority: 0, selection_type: '' },
      Category: { priority: 0, selection_type: '' },
      Price: { priority: 0, selection_type: '' },
      Sort: { priority: 0, selection_type: '' },
    },
    price: [],
    sort: [],
  }),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IProductFilterListStateV1;

const GetFilterListReducerV1: Reducer<IProductFilterListStateV1, GetProductFilterListV1> = (
  state = initialFilterListState,
  action: GetProductFilterListV1,
): IProductFilterListStateV1 => {
  switch (action.type) {
    case GetProductFilterListActionTypes.GET_PRODUCT_FILTER_LIST_V1:
      console.log('initialised', action.type);
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetProductFilterListActionTypes.GET_PRODUCT_FILTER_SUCCESS_V1: {
      console.log('success', action.type);

      return {
        ...state,
        filterList: new ProductFilterList(action.payload.filterList),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetProductFilterListActionTypes.GET_PRODUCT_FILTER_FAILURE_V1:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetProductFilterListActionTypes.GET_PRODUCT_FILTER_RESET_V1:
      return initialFilterListState;

    default:
      return state;
  }
};

export default GetFilterListReducerV1;
