import common_en from './en/common.json';
import auth_en from './en/auth.json';
import router_en from './en/router.json';
import dashboard_en from './en/dashboard.json';
import permission_en from './en/permission.json';
import products_en from './en/products.json';

const langSetupOptions = {
  resources: {
    en: {
      common: common_en,
      auth: auth_en,
      router: router_en,
      dashboard: dashboard_en,
      permission: permission_en,
      products: products_en,
    },
  },
  ns: ['common', 'auth', 'dashboard', 'router', 'permission', 'products'],
  defaultNS: 'common',
};

export default langSetupOptions;
