import { createAction, ActionType } from 'typesafe-actions';

import { IPageInfo } from '../../../models/PageInfo';
import { IErrorActionData } from '../../../models/Error';
import { IPage } from '../../../utils/sortFilterFramework';
import { IUser } from '../models/UserDetailsList';

export enum GetUserDetailsListActionTypes {
  GET_USER_DETAILS_LIST = 'GET_USER_DETAILS_LIST',
  GET_USER_DETAILS_LIST_SUCCESS = 'GET_USER_DETAILS_LIST_SUCCESS',
  GET_USER_DETAILS_LIST_FAILED = 'GET_USER_DETAILS_LIST_FAILED',
  GET_USER_DETAILS_LIST_RESET = 'GET_USER_DETAILS_LIST_RESET',
}

interface IGetUserDetailsListActionData {
  userAliasIdList?: string[];
  page?: IPage;
}

interface IGetUsersSuccessActionData {
  userDetailsList: Array<IUser>;
  pageInfo: IPageInfo;
}

export const getUserDetailsList = createAction(GetUserDetailsListActionTypes.GET_USER_DETAILS_LIST)<
IGetUserDetailsListActionData
>();
export const getUserDetailsListSuccess = createAction(
  GetUserDetailsListActionTypes.GET_USER_DETAILS_LIST_SUCCESS,
)<IGetUsersSuccessActionData>();
export const getUserDetailsListFailure = createAction(
  GetUserDetailsListActionTypes.GET_USER_DETAILS_LIST_FAILED,
)<IErrorActionData>();

export const getUserDetailsListReset = createAction(GetUserDetailsListActionTypes.GET_USER_DETAILS_LIST_RESET)();

export type GetUserDetailsListAction = ActionType<typeof getUserDetailsList>;
type GetUserDetailsListSuccessAction = ActionType<typeof getUserDetailsListSuccess>;
type GetUserDetailsListFailureAction = ActionType<typeof getUserDetailsListFailure>;
type GetUserDetailsListResetAction = ActionType<typeof getUserDetailsListReset>;

export type GetUserDetailsListActions = GetUserDetailsListAction
| GetUserDetailsListSuccessAction
| GetUserDetailsListFailureAction
| GetUserDetailsListResetAction;
