/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { gql } from '@apollo/client';

import client from 'config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IGetAutoSuggestPayload } from '../models/ProductList';

const GET_AUTO_SUGGEST = gql`
  query getAutoSuggest($path: String!, $payload: any) {
    getAutoSuggest (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "searchPlaygroundUri") {
        data
      }
}
`;

const getAutoSuggestService = (payload: IGetAutoSuggestPayload) => client.query({
  query: GET_AUTO_SUGGEST,
  variables: { path: URL.getAutoSuggest, payload },
});

export default getAutoSuggestService;
