/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Reducer } from 'redux';

import { DealList, mergeTwoDealLists } from '../models/DealList'; // Adjust the import
import { GetDealListActionTypes, GetDealListActionsV1 } from '../actions/GetDealList'; // Adjust the import
import { PageInfo } from '../../../models/PageInfo';
import { IErrorActionData } from '../../../models/Error';
import { defaultPageSize } from '../../../constants/Common';

export interface IDealListStateV1 {
  dealList: DealList; // Adjust the type
  pageInfo: PageInfo;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialDealListState = {
  dealList: new DealList([]), // Adjust the type
  pageInfo: new PageInfo({ pageSize: defaultPageSize, cursor: 0, hasNext: true }),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IDealListStateV1;

const GetDealListReducerV1: Reducer<IDealListStateV1, GetDealListActionsV1> = (
  state = initialDealListState,
  action: GetDealListActionsV1,
): IDealListStateV1 => {
  switch (action.type) {
    case GetDealListActionTypes.GET_DEAL_LIST_V1:
      console.log('initialed', action.type);
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetDealListActionTypes.GET_DEAL_LIST_SUCCESS_V1: {
      console.log('success', action.type);
      const isAppend = ((state.pageInfo.cursor ?? 0) < (action.payload.pageInfo?.cursor ?? 0)
          && (state.pageInfo.cursor as number) % 10 === 0)
        || ((action.payload.pageInfo.cursor as number) > 10 && !action.payload.pageInfo.hasNext)
        || ((action.payload.pageInfo.cursor as number) === 10
          && !action.payload.pageInfo.hasNext
          && action.payload.dealList === null);

      const newDealList = isAppend ? mergeTwoDealLists( // Adjust the function name and type
        state.dealList.dealList,
        action.payload.dealList,
      ) : state.dealList.dealList;

      console.log('action_payload_deal_list', action.payload);
      return {
        ...state,
        dealList: new DealList(newDealList), // Adjust the type and property name
        pageInfo: new PageInfo({
          pageSize: action.payload.pageInfo.pageSize,
          cursor: action.payload.pageInfo.cursor,
          hasNext: action.payload.pageInfo.hasNext,
        }),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetDealListActionTypes.GET_DEAL_LIST_FAILED_V1:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetDealListActionTypes.GET_DEAL_LIST_RESET_V1:
      return initialDealListState;

    default:
      return state;
  }
};

export default GetDealListReducerV1;
