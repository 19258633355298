import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import { IPage, constructUrlWithParams } from '../../../utils/sortFilterFramework';
import URL from '../../../constants/ApiEndPoints';

const GET_INTERNAL_USER_LIST = gql`
  query getInternalUserList($path: String!) {
      internalUserList @rest(method: "GET", path: $path) {
        data
        pageInfo
      }
  }
`;

const getInternalUserListService = (page?: IPage) => {
  const url = constructUrlWithParams(URL.getInternalUserList, undefined, undefined, page);
  return client.query({ query: GET_INTERNAL_USER_LIST, variables: { path: url } });
};

export default getInternalUserListService;
