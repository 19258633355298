import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IGetCustomerListPayload } from '../models/CustomerList';

const GET_CUSTOMER_LIST = gql`
  query getCustomerList($path: String!, $payload: any) {
    getCustomerList (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "searchPlaygroundUri") {
        data
        pageInfo
      }
  }
`;

const getCustomerListServiceV1 = (payload: IGetCustomerListPayload) => client.query({
  query: GET_CUSTOMER_LIST,
  variables: { path: URL.getCustomerListV2, payload },
});

export default getCustomerListServiceV1;
