/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getCustomerListSuccess,
  getCustomerListFailure,
  GetCustomerListAction,
  GetCustomerListActionTypes,
} from '../actions/GetCustomerList';
// import getCustomerListService from '../services/GetCustomerList';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import getCustomerListServiceV1 from '../services/GetCustomerList';

const GetCustomerListEpicV1 = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetCustomerListActionTypes.GET_CUSTOMER_LIST_V1)),
  mergeMap((action: GetCustomerListAction) => from(
    getCustomerListServiceV1(
      action.payload.payload,
    ),
  ).pipe(
    map((response) => getCustomerListSuccess({
      customerList: response.data.getCustomerList.data,
      pageInfo: response.data.getCustomerList.pageInfo,
    })),
    catchError((error: IErrorActionData) => of(
      getCustomerListFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetCustomerListEpicV1;
