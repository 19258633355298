export const defaultPageSize = 10;
export const defaultMaxPageSize = 1000;
export const maxPageSizeTemp = 10000; // This value will be updated/removed once Elastic Search API is implemented
export const defaultCursorValue = '0';
export const defaultListFetchValue = 20;
export const maxPageSize = 1000;
export const defaultPageNumber = 1;
export const numberOfColumns = 4;

export const defaultOTPTimeout = 30000;

export enum LoginMode {
  password = 'password',
  otp = 'otp',
  login = 'login',
}

export enum LoginType {
  phoneno = 'phoneno',
  email = 'email',
}

export enum CountryCallingCode {
  IN = '+91',
}

export type CountryCodes = 'IN' | 'US';

export enum CurrencyCodes {
  IN = '\u20B9',
  US = '\u0024',
}

export enum TimeConversion {
  DayInSeconds = 86400,
  ISTOffsetInSeconds = 19800,
  SecondToMilliseconds = 1000,
  EpochLocalTime1stJan2020 = 1577817000,
}

export enum Units {
  Minutes = 'mins',
  Milliliters = 'mm',
}
export enum SpecialCharacters {
  Bullet = '\u2022',
  WhiteSpace = '\u00A0',
}

export enum AnchorHorizontalPosition {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}

export enum AnchorVerticalPosition {
  Bottom = 'bottom',
  Top = 'top',
  Center = 'center',
}

export enum DateTimeFormats {
  DateTypeOne = 'DD/MMM/YYYY',
  DateTypeTwo = ' DD MMM YYYY',
  DateTypeThree = 'DD/MM/YYYY',
}

export enum AliasIdPrefix {
  User = 'IMREP',
  Vehicle = 'VEH',
}

export enum ModeType {
  ADD = 'Add',
  EDIT = 'Edit',
}

export type DistanceCodes = 'km' | 'mtr';
