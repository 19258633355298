interface IProduct {
  imageUrl: string,
  isMultiVariant: boolean,
  isVariant: boolean,
  productAliasId: string,
  productName: string,
  sku: string,
  variantAliasId: string,
}

export interface IAutoSuggest {
  products: IProduct[],
}

export class AutoSuggest {
  data = {} as IAutoSuggest;

  constructor(autoSuggest: IAutoSuggest) {
    this.data = autoSuggest;
  }
}
