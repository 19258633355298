import { ActionType, createAction } from 'typesafe-actions';

import { IErrorActionData } from 'models/Error';
import { IGetAutoSuggestPayload } from '../models/ProductList';
import { IAutoSuggest } from '../models/AutoSuggest';

export enum GetAutoSuggestActionTypes {
  GET_AUTO_SUGGEST_LIST_V1 = 'GET_AUTO_SUGGEST_LIST_V1',
  GET_AUTO_SUGGEST_SUCCESS_V1 = 'GET_AUTO_SUGGEST_SUCCESS_V1',
  GET_AUTO_SUGGEST_FAILURE_V1 = 'GET_AUTO_SUGGEST_FAILURE_V1',
}

interface IGetAutoSuggestActionData {
  payload: IGetAutoSuggestPayload;
}

interface IGetAutoSuggestSucessActionData {
  autoSuggest: IAutoSuggest;
}

export const getAutoSuggest = createAction(
  GetAutoSuggestActionTypes.GET_AUTO_SUGGEST_LIST_V1,
)<IGetAutoSuggestActionData>();
export const getAutoSuggestSuccess = createAction(
  GetAutoSuggestActionTypes.GET_AUTO_SUGGEST_SUCCESS_V1,
)<IGetAutoSuggestSucessActionData>();
export const getAutoSuggestFailure = createAction(
  GetAutoSuggestActionTypes.GET_AUTO_SUGGEST_FAILURE_V1,
)<IErrorActionData>();

export type GetAutoSuggestAction = ActionType<typeof getAutoSuggest>;
type GetAutoSuggestSuccessAction = ActionType<typeof getAutoSuggestSuccess>;
type GetAutoSuggestFailureAction = ActionType<typeof getAutoSuggestFailure>;

export type GetAutoSuggestActionsV1 = GetAutoSuggestAction
| GetAutoSuggestSuccessAction
| GetAutoSuggestFailureAction;
