/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Reducer } from 'redux';

import { ProductList, mergeTwoLists } from '../models/ProductList';
import { GetProductListActionTypes, GetProductListActionsV4 } from '../actions/GetProductList';
import { PageInfo } from '../../../models/PageInfo';
import { IErrorActionData } from '../../../models/Error';
import { defaultPageSize } from '../../../constants/Common';

export interface IProductListStateV4 {
  productList: ProductList;
  esQuery: any;
  pageInfo: PageInfo;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialProductListState = {
  productList: new ProductList([]),
  esQuery: null,
  pageInfo: new PageInfo({ pageSize: defaultPageSize, cursor: 0, hasNext: true }),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IProductListStateV4;

const GetProductListReducerV4: Reducer<IProductListStateV4, GetProductListActionsV4> = (
  state = initialProductListState,
  action: GetProductListActionsV4,
): IProductListStateV4 => {
  switch (action.type) {
    case GetProductListActionTypes.GET_PRODUCT_LIST_V4:
      console.log('initialed', action.type);
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetProductListActionTypes.GET_PRODUCT_LIST_SUCCESS_V4: {
      console.log('success', action.type);

      const newProductList = mergeTwoLists(
        state.productList.productList,
        action.payload.productList,
      );
      return {
        ...state,
        productList: new ProductList(newProductList),
        esQuery: action.payload.esQuery,
        pageInfo: new PageInfo({
          cursor: action.payload.pageInfo.cursor,
          pageSize: action.payload.pageInfo.pageSize,
          hasNext: action.payload.pageInfo.hasNext,
          pageNumber: action.payload.pageInfo.pageNumber,
          totalPages: action.payload.pageInfo.totalPages,
        }),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetProductListActionTypes.GET_PRODUCT_LIST_FAILED_V4:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetProductListActionTypes.GET_PRODUCT_LIST_RESET_V4:
      return initialProductListState;

    default:
      return state;
  }
};

export default GetProductListReducerV4;
