/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import RootAction from 'config/store/RootAction';
import RootState from 'config/store/RootState';
import { ActionsObservable, StateObservable } from 'redux-observable';
import {
  catchError, filter, map, mergeMap,
} from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import { from, of } from 'rxjs';

import { IErrorActionData } from 'models/Error';
import {
  GetAutoSuggestAction, GetAutoSuggestActionTypes, getAutoSuggestFailure, getAutoSuggestSuccess,
} from '../actions/GetAutoSuggest';
import getAutoSuggestService from '../services/GetAutoSuggest';

const GetAutoSuggestEpicV1 = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetAutoSuggestActionTypes.GET_AUTO_SUGGEST_LIST_V1)),
  mergeMap((action: GetAutoSuggestAction) => from(
    getAutoSuggestService(
      action.payload.payload,
    ),
  ).pipe(
    map((response) => getAutoSuggestSuccess({
      autoSuggest: response.data.getAutoSuggest.data,
    })),
    catchError((error: IErrorActionData) => of(
      getAutoSuggestFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetAutoSuggestEpicV1;
