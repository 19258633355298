import { gql } from '@apollo/client';
import { StringFormats } from 'constants/Strings';
import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IGetProductListPayload } from '../models/ProductList';

const GET_PRODUCT_LIST = gql`
  query getProductList($path: String!, $payload: any) {
    getProductList (path: $path, payload: $payload)
      @rest(method: "POST", path: $path, bodyKey: "payload", endpoint: "searchPlaygroundUri") {
        data
        pageInfo
      }
  }
`;

const getProductListServiceV4 = (payload: IGetProductListPayload, searchType: string) => {
  let url = URL.getProductListV4; // Default URL
  switch (searchType.toLocaleLowerCase()) {
    case 'default':
      url = URL.getProductListV4;
      break;
    case 'vector search':
      url = URL.getProductListVectorSearch;
      break;
    case 'electricals lexical search':
      url = URL.getProductFanList;
      break;
    case 'electricals semantic search':
      url = URL.getProductFanListVectorSearch;
      break;
    case 'algolia search':
      url = URL.getProductListAlgoliaSearch;
      break;
    default:
      url = URL.getProductListV4;
      break;
  }

  // Return the result of the query (Promise)
  return client.query({
    query: GET_PRODUCT_LIST,
    variables: { path: url, payload },
  });
};

export default getProductListServiceV4;
