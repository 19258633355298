import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import { IPage, constructUrlWithParams, FilterOperands } from '../../../utils/sortFilterFramework';
import URL from '../../../constants/ApiEndPoints';

const GET_USER_DETAILS_LIST = gql`
  query getUserDetailsList($path: String!) {
    userDetailsList @rest(method: "GET", path: $path) {
        data
        pageInfo
      }
  }
`;

const getUserDetailsListService = (userIdList: string[], page?: IPage) => {
  const userIds = userIdList.map((user) => Number(user.replace(/\D/g, '')));
  const url = constructUrlWithParams(URL.getUserDetailsList,
    [{ property: 'ids', value: userIds, operand: FilterOperands.EQ }], undefined, page);
  return client.query({ query: GET_USER_DETAILS_LIST, variables: { path: url } });
};

export default getUserDetailsListService;
