import React, { FunctionComponent } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';

import { useTypedSelector } from '../config/store';
import MainLayout from '../components/IMLayout/MainLayout';

interface IPrivateRoute {
  path: string;
  reqModulePermission?: string[],
  exact?: boolean,
}
const PrivateRoute: FunctionComponent<IPrivateRoute> = (props) => {
  const userProfileState = useTypedSelector((state) => state.userProfile);
  const { permission } = userProfileState.userProfileData;

  const hasPermission = () => {
    if (!props.reqModulePermission?.length) return true;
    return (Object.keys(permission?.module ?? {}).some((item) => props.reqModulePermission?.includes(item)));
  };

  return (
    <>
      { userProfileState.isSuccess
      && (
      <Route
        path={props.path}
        exact={props.exact || false}
        render={({ location: string }) => (hasPermission()
          ? <>{props.children}</>
          : <Redirect to={{ pathname: '/' }} />)}
      />
      )}
    </>
  );
};

export default PrivateRoute;
