/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { combineEpics } from 'redux-observable';

import LoginEpic from 'feature/auth/epics/Login';
import AutoLoginEpic from 'feature/auth/epics/AutoLogin';
import VerifyOtpEpic from 'feature/auth/epics/VerifyOTP';
import ResendOtpEpic from 'feature/auth/epics/ResendOTP';
import LogoutEpic from 'feature/auth/epics/Logout';
import GetUserProfileEpic from 'feature/auth/epics/GetUserProfile';
import GetUserDetailsEpic from 'feature/user/epics/GetUserDetails';
import GetUserDetailsListEpic from 'feature/user/epics/GetUserDetailsList';
import GetInternalUserListEpic from 'feature/user/epics/GetInternalUserList';
import GetFilterListEpicV1 from 'feature/search/epics/GetProductFilterList';
import GetAutoSuggestEpicV1 from 'feature/search/epics/GetAutoSuggest';
import GetDealListEpicV1 from 'feature/search/epics/GetDealList';
import GetProductListEpicV4 from '../../feature/search/epics/GetProductList';
import GetCustomerListEpicV1 from '../../feature/search/epics/GetCustomerList';
import GetEnquiryListEpicV1 from '../../feature/search/epics/GetEnquiryList';
import GetProjectListEpicV1 from '../../feature/search/epics/GetProjectList';
import GetProductDetailsEpicV4 from '../../feature/search/epics/GetProductDetails';

const RootEpic = combineEpics(
  LoginEpic,
  AutoLoginEpic,
  VerifyOtpEpic,
  ResendOtpEpic,
  LogoutEpic,
  GetUserProfileEpic,
  GetUserDetailsEpic,
  GetUserDetailsListEpic,
  GetInternalUserListEpic,
  GetProductListEpicV4,
  GetFilterListEpicV1,
  GetCustomerListEpicV1,
  GetEnquiryListEpicV1,
  GetProjectListEpicV1,
  GetAutoSuggestEpicV1,
  GetProductDetailsEpicV4,
  GetDealListEpicV1,
);

export default RootEpic;
