import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { ILoginActionData } from '../actions/Login';

const LOGIN = gql`
  query login($path: String!, $data: any) {
    login(input: $data, path: $path) 
      @rest(method: "POST", path: $path, endpoint: "pheonix") {
        data
    }
  }
`;

const loginService = (payload: ILoginActionData) => client.query({ query: LOGIN, variables: { data: payload, path: URL.login } });

export default loginService;
