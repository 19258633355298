/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getProjectListSuccess,
  getProjectListFailure,
  GetProjectListAction,
  GetProjectListActionTypes,
} from '../actions/GetProjectList';

import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import getProjectListServiceV1 from '../services/GetProjectList';

const GetProjectListEpicV1 = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetProjectListActionTypes.GET_PROJECT_LIST_V1)),
  mergeMap((action: GetProjectListAction) => from(
    getProjectListServiceV1(
      action.payload.payload,
    ),
  ).pipe(
    map((response) => getProjectListSuccess({
      projectList: response.data.getProjectList.data.data || [],
      pageInfo: response.data.getProjectList.data.pageInfo,
    })),
    catchError((error: IErrorActionData) => of(
      getProjectListFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetProjectListEpicV1;
