import { Reducer } from 'redux';

import { PageInfo } from 'models/PageInfo';
import { defaultCursorValue, defaultPageSize } from 'constants/Common';
import { InternalUserList, mergeTwoLists } from '../models/InternalUserList';
import { GetInternalUserListActions, GetInternalUserListActionTypes } from '../actions/GetInternalUserList';
import { IErrorActionData } from '../../../models/Error';

export interface IGetInternalUserListState {
  internalUserList: InternalUserList;
  pageInfo: PageInfo
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialInternalUserListState = {
  internalUserList: new InternalUserList([]),
  pageInfo: new PageInfo({ pageSize: defaultPageSize, cursor: defaultCursorValue, hasNext: false }),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IGetInternalUserListState;

const GetInternalUserListReducer: Reducer<IGetInternalUserListState, GetInternalUserListActions> = (
  state = initialInternalUserListState,
  action: GetInternalUserListActions,
): IGetInternalUserListState => {
  switch (action.type) {
    case GetInternalUserListActionTypes.GET_INTERNAL_USER_LIST:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetInternalUserListActionTypes.GET_INTERNAL_USER_LIST_SUCCESS: {
      const userList = mergeTwoLists(
        state.internalUserList.internalUserList,
        action.payload.internalUserList,
      );

      return {
        ...state,
        internalUserList: new InternalUserList(userList),
        pageInfo: new PageInfo({
          pageSize: action.payload.pageInfo.pageSize,
          cursor: action.payload.pageInfo.cursor,
          hasNext: action.payload.pageInfo.hasNext,
          pageNumber: action.payload.pageInfo.pageNumber,
          totalPages: action.payload.pageInfo.totalPages,
        }),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetInternalUserListActionTypes.GET_INTERNAL_USER_LIST_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetInternalUserListActionTypes.GET_INTERNAL_USER_LIST_RESET:
      return initialInternalUserListState;

    default:
      return state;
  }
};

export default GetInternalUserListReducer;
