import { Reducer } from 'redux';

import { mergeTwoLists, UserDetailsList } from '../models/UserDetailsList';
import { GetUserDetailsListActions, GetUserDetailsListActionTypes } from '../actions/GetUserDetailsList';
import { PageInfo } from '../../../models/PageInfo';
import { IErrorActionData } from '../../../models/Error';
import { defaultPageSize, defaultCursorValue } from '../../../constants/Common';

export interface IUserDetailsListState {
  userDetailsList: UserDetailsList;
  pageInfo: PageInfo;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialUserDetailsListState = {
  userDetailsList: new UserDetailsList([]),
  pageInfo: new PageInfo({ pageSize: defaultPageSize, cursor: defaultCursorValue, hasNext: false }),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IUserDetailsListState;

const GetUserDetailsListReducer: Reducer<IUserDetailsListState, GetUserDetailsListActions> = (
  state = initialUserDetailsListState,
  action: GetUserDetailsListActions,
): IUserDetailsListState => {
  switch (action.type) {
    case GetUserDetailsListActionTypes.GET_USER_DETAILS_LIST:
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetUserDetailsListActionTypes.GET_USER_DETAILS_LIST_SUCCESS: {
      const newUserDetailsList = mergeTwoLists(
        state.userDetailsList.userDetailsList,
        action.payload.userDetailsList,
      );
      return {
        ...state,
        userDetailsList: new UserDetailsList(newUserDetailsList),
        pageInfo: new PageInfo({
          pageSize: action.payload.pageInfo.pageSize,
          cursor: action.payload.pageInfo.cursor,
          hasNext: action.payload.pageInfo.hasNext,
          pageNumber: action.payload.pageInfo.pageNumber,
          totalPages: action.payload.pageInfo.totalPages,
        }),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };
    }

    case GetUserDetailsListActionTypes.GET_USER_DETAILS_LIST_FAILED:
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    case GetUserDetailsListActionTypes.GET_USER_DETAILS_LIST_RESET:
      return initialUserDetailsListState;

    default:
      return state;
  }
};

export default GetUserDetailsListReducer;
