export interface IGetProjectListPayload {
  city?: string;
  state?: string;
  searchString: string;
  projectId?: string;
  cursor?: number | string;
  pageSize: number;
}

export interface AddressData{
  city?: string;
  state?: string;
}

export interface PeopleId{
  id: number;
}

export interface IProject {
  address: AddressData;
  createdAt: number;
  updatedAt: number;
  lastInteraction: number;
  projectId: string;
  projectName: string;
  projectType: string;
  peopleId: PeopleId[];
}

export class ProjectList {
  projectList: Array<IProject> = [];

  constructor(projectList: Array<IProject>) {
    this.projectList = projectList;
  }
}

export const mergeTwoLists = (
  existingList: Array<IProject>,
  payloadList: Array<IProject>,
) => {
  if (!existingList.length) return payloadList;
  if (!payloadList.length) return existingList;
  return existingList.concat(payloadList);
};
