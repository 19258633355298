export interface IGetCustomerListPayload {
  searchString: string;
  relationshipType: string[];
  urmCustomerIds : string[];
  cursor?: number | string;
  pageSize: number;
  filters?:[];
}

export interface ICustomer {
  businessType: string;
  companyType: string;
  customerId: number;
  customerName: string;
  industryType: string;
  kycStatus: string;
  phoneNumber: string;
  state: string;
}

export class CustomerList {
  customerList: Array<ICustomer> = [];

  constructor(customerList: Array<ICustomer>) {
    this.customerList = customerList;
  }
}

export const mergeTwoLists = (
  existingList: Array<ICustomer>,
  payloadList: Array<ICustomer>,
) => {
  if (!existingList.length) return payloadList;
  if (!payloadList.length) return existingList;
  return existingList.concat(payloadList);
};
