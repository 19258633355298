import { Reducer } from 'redux';

import { IErrorActionData } from 'models/Error';
import { AutoSuggest, IAutoSuggest } from '../models/AutoSuggest';
import { GetAutoSuggestActionTypes, GetAutoSuggestActionsV1 } from '../actions/GetAutoSuggest';

export interface IAutoSuggestStateV1 {
  autoSuggest: AutoSuggest;
  isProcessing: boolean;
  isSuccess: boolean;
  error?: IErrorActionData;
}

const initialAutoSuggestState = {
  autoSuggest: new AutoSuggest({
    products: [],
  }),
  isProcessing: false,
  isSuccess: false,
  error: undefined,
} as IAutoSuggestStateV1;

const GetAutoSuggestReducerV1: Reducer<IAutoSuggestStateV1, GetAutoSuggestActionsV1> = (
  state = initialAutoSuggestState,
  action: GetAutoSuggestActionsV1,
): IAutoSuggestStateV1 => {
  switch (action.type) {
    case GetAutoSuggestActionTypes.GET_AUTO_SUGGEST_LIST_V1:
      console.log('initialised', action.type);
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        error: undefined,
      };

    case GetAutoSuggestActionTypes.GET_AUTO_SUGGEST_SUCCESS_V1:
      console.log('success', action.type);
      return {
        ...state,
        autoSuggest: new AutoSuggest(action.payload.autoSuggest),
        isProcessing: false,
        isSuccess: true,
        error: undefined,
      };

    case GetAutoSuggestActionTypes.GET_AUTO_SUGGEST_FAILURE_V1:
      console.log('failure', action.type);
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: {
          errorCode: action.payload.errorCode,
          errorMessage: action.payload.errorMessage,
          callBack: action.payload.callBack,
        },
      };

    default:
      return state;
  }
};

export default GetAutoSuggestReducerV1;
