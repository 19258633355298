/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';
import { IGetEnquiryListPayload } from '../models/EnquiryList';

const GET_ENQUIRY_LIST = gql`
  query getEnquiryList($path: String!) {
    getEnquiryList(path: $path) @rest(method: "GET", path: $path, endpoint: "searchPlaygroundUri") {
      data
      pageInfo
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getEnquiryListServiceV1 = (payload: IGetEnquiryListPayload) => {
  const {
    cursor, pageSize, searchString, creationStartDateGe, creationEndDateLe, followUpStartDateGe,
    followUpEndDateLe, city, state, enquiryStatus, source, userAliasId,
  } = payload;

  // eslint-disable-next-line max-len, @typescript-eslint/restrict-template-expressions
  const url = `${URL.getEnquiryListV1}?page_size=${pageSize}&cursor=${cursor}&search_string=${searchString}&user_alias_id=${userAliasId}&enquiry_status=${enquiryStatus}&source=${source}&state=${state}&city=${city}&follow_up_end_date_le=${followUpEndDateLe}&follow_up_start_date_ge=${followUpStartDateGe}&creation_end_date_le=${creationEndDateLe}&creation_start_date_ge=${creationStartDateGe}`;
  // eslint-disable-next-line no-console
  return client.query({
    query: GET_ENQUIRY_LIST,
    variables: { path: url.toString() },
  });
};

export default getEnquiryListServiceV1;
