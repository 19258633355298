import { createAction, ActionType } from 'typesafe-actions';
import { IErrorActionData } from '../../../models/Error';
import { IPageInfo } from '../../../models/PageInfo';
import { IGetEnquiryListPayload, IEnquiry } from '../models/EnquiryList';

export enum GetEnquiryListActionTypes {
  GET_ENQUIRY_LIST_V1 = 'GET_ENQUIRY_LIST_V1',
  GET_ENQUIRY_LIST_SUCCESS_V1 = 'GET_ENQUIRY_LIST_SUCCESS_V1',
  GET_ENQUIRY_LIST_FAILED_V1 = 'GET_ENQUIRY_LIST_FAILED_V1',
  GET_ENQUIRY_LIST_RESET_V1 = 'GET_ENQUIRY_LIST_RESET_V1',
}

interface IGetEnquiryListActionData {
  payload: IGetEnquiryListPayload;
}

interface IGetEnquiryListSuccessActionData {
  enquiryList: Array<IEnquiry>;
  pageInfo: IPageInfo;
}

// create actions
export const getEnquiryListV1 = createAction(GetEnquiryListActionTypes.GET_ENQUIRY_LIST_V1)<IGetEnquiryListActionData>();
export const getEnquiryListSuccess = createAction(
  GetEnquiryListActionTypes.GET_ENQUIRY_LIST_SUCCESS_V1,
)<IGetEnquiryListSuccessActionData>();
export const getEnquiryListFailure = createAction(
  GetEnquiryListActionTypes.GET_ENQUIRY_LIST_FAILED_V1,
)<IErrorActionData>();
export const getEnquiryListReset = createAction(GetEnquiryListActionTypes.GET_ENQUIRY_LIST_RESET_V1)();

export type GetEnquiryListAction = ActionType<typeof getEnquiryListV1>;
type GetEnquiryListSuccessAction = ActionType<typeof getEnquiryListSuccess>;
type GetEnquiryListFailureAction = ActionType<typeof getEnquiryListFailure>;
type GetEnquiryListResetAction = ActionType<typeof getEnquiryListReset>;

export type GetEnquiryListActionsV1 = GetEnquiryListAction
| GetEnquiryListSuccessAction
| GetEnquiryListFailureAction
| GetEnquiryListResetAction;
