/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getEnquiryListSuccess,
  getEnquiryListFailure,
  GetEnquiryListAction,
  GetEnquiryListActionTypes,
} from '../actions/GetEnquiryList';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';
import getEnquiryListServiceV1 from '../services/GetEnquiryList'; // Adjust the import

const GetEnquiryListEpicV1 = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetEnquiryListActionTypes.GET_ENQUIRY_LIST_V1)),
  mergeMap((action: GetEnquiryListAction) => from(
    getEnquiryListServiceV1(
      action.payload.payload,
    ),
  ).pipe(
    map((response) => getEnquiryListSuccess(
      {
        enquiryList: response.data.getEnquiryList.data.enquiryList || 0,
        pageInfo: {
          pageSize: response.data.getEnquiryList.data.pageInfo.pageSize || 0,
          cursor: response.data.getEnquiryList.data.pageInfo.cursor || 0,
          hasNext: response.data.getEnquiryList.data.pageInfo.hasNext || false,
          pageNumber: response.data.getEnquiryList.data.pageInfo.pageNumber || 1,
          totalPages: response.data.getEnquiryList.data.pageInfo.totalPages || 1,
        },
      },
    )),
    catchError((error: IErrorActionData) => of(
      getEnquiryListFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetEnquiryListEpicV1;
