/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { from, of } from 'rxjs';

import {
  getUserDetailsListSuccess,
  getUserDetailsListFailure,
  GetUserDetailsListAction,
  GetUserDetailsListActionTypes,
} from '../actions/GetUserDetailsList';
import getUserDetailsListService from '../services/GetUserDetailsList';
import { IErrorActionData } from '../../../models/Error';
import RootState from '../../../config/store/RootState';
import RootAction from '../../../config/store/RootAction';

const GetUserDetailsListEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => action$.pipe(
  filter(isOfType(GetUserDetailsListActionTypes.GET_USER_DETAILS_LIST)),
  mergeMap((action: GetUserDetailsListAction) => from(
    getUserDetailsListService(
      action.payload.userAliasIdList || [],
      action.payload.page,
    ),
  ).pipe(
    map((response) => getUserDetailsListSuccess({
      userDetailsList: response.data.userDetailsList.data,
      pageInfo: {
        pageSize: response.data.userDetailsList.pageInfo.pageSize,
        cursor: response.data.userDetailsList.pageInfo.cursor,
        hasNext: response.data.userDetailsList.pageInfo.hasNext,
        pageNumber: response.data.userDetailsList.pageInfo.pageNumber,
        totalPages: response.data.userDetailsList.pageInfo.totalPages,
      },
    })),
    catchError((error: IErrorActionData) => of(
      getUserDetailsListFailure({
        errorCode: error.errorCode || 500,
        errorMessage: error.errorMessage,
        callBack: error.callBack,
      }),
    )),
  )),
);

export default GetUserDetailsListEpic;
