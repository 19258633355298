import { IKycDetails } from './KycModel';

export interface IUser {
  userAliasId: number;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  landline?: string;
  email?: string;
  designation?: string;
  department?: string;
  contactViaWhatsapp?: string;
  isActive?: boolean;
  kycStatus?: string;
  kycDetails?: Array<IKycDetails>;
  imgUrl?: string;
}

export class UserDetailsList {
  userDetailsList: IUser[];
  constructor(userDetailsList: IUser[]) {
    this.userDetailsList = userDetailsList;
  }
}

/**
 * Merging Two Lists
 * @param existingList - existingList
 * @param payloadList - Action Payload List
 */
export const mergeTwoLists = (
  existingList: Array<IUser>,
  payloadList: Array<IUser>,
) => {
  if (!payloadList.length) return existingList;
  if (!existingList.length) return payloadList;
  return existingList.concat(payloadList);
};
