import { createAction, ActionType } from 'typesafe-actions';

import { IUserDetails } from '../models/UserDetails';
import { IErrorActionData } from '../../../models/Error';

export enum GetUserDetailsActionTypes {
  GET_USER_DETAILS = 'GET_USER_DETAILS',
  GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS',
  GET_USER_DETAILS_FAILED = 'GET_USER_DETAILS_FAILED',
  GET_USER_DETAILS_RESET = 'GET_USER_DETAILS_RESET',
}

interface IGetUserDetailsActionData {
  aliasId: string;
}

interface IGetUserDetailsSuccessActionData {
  data: IUserDetails;
  aliasId: string;
}
export interface IErrorUserDetails extends IErrorActionData {
  aliasId: string;
}

export const getUserDetails = createAction(GetUserDetailsActionTypes.GET_USER_DETAILS)<IGetUserDetailsActionData>();
export const getUserDetailsSuccess = createAction(GetUserDetailsActionTypes.GET_USER_DETAILS_SUCCESS)
<IGetUserDetailsSuccessActionData>();
export const getUserDetailsFailure = createAction(GetUserDetailsActionTypes.GET_USER_DETAILS_FAILED)<IErrorUserDetails>();
export const getUserDetailsReset = createAction(GetUserDetailsActionTypes.GET_USER_DETAILS_RESET)();

export type GetUserDetailsAction = ActionType<typeof getUserDetails>;
type GetUserDetailsSuccessAction = ActionType<typeof getUserDetailsSuccess>;
type GetUserDetailsFailureAction = ActionType<typeof getUserDetailsFailure>;
type GetUserDetailsResetAction = ActionType<typeof getUserDetailsReset>;

export type GetUserDetailsActions = GetUserDetailsAction
| GetUserDetailsSuccessAction
| GetUserDetailsFailureAction
| GetUserDetailsResetAction;
