import { gql } from '@apollo/client';

import client from '../../../config/apolloClient';
import URL from '../../../constants/ApiEndPoints';

const GET_USER_DETAILS = gql`
  query getUserDetails($path: String!) {
      userDetails @rest(method: "GET", path: $path) {
        data
      }
  }
`;

const getUserDetailsService = (userAliasId: string) => {
  const url = URL.getUserDetails.replace('{aliasId}', userAliasId.replace(/\D/g, ''));
  return client.query({ query: GET_USER_DETAILS, variables: { path: url } });
};

export default getUserDetailsService;
